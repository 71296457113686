import { Button } from "react-bootstrap";
import { useState, useContext, useEffect } from "react";
import coin from "../../../assets/images/clientlayout/coin.svg";
import green from "../../../assets/images/clientlayout/green.svg";
import red from "../../../assets/images/clientlayout/red.svg";
import DepositPopup from "../depositpopup/depositpopup";
import WithdrawPopup from "../withdraw-popup/withdrawpopup";
import UserContext from "../../../context/UserContext";
import "./menu.css";
import { Link } from "react-router-dom";
import { getTransactions } from "../../../utils/Api";
import NoData from "../../noData";
import { changeCurrencyFormat, getDate, getTime } from "../../../utils";

const WalletMenu = () => {
  const { user } = useContext(UserContext);
  const [deposit, setDeposit] = useState(false);
  const handleShow = () => setDeposit(true);
  const handleClose = () => {
    setDeposit(false);
  };
  const [withdraw, setWithdraw] = useState(false);
  const handleWithShow = () => {
    setWithdraw(!withdraw);
  };

  const multiplyValue = (amount = 0, multiplyBy = 0) => {
    const res = amount * multiplyBy;
    return res;
  };
  const [transactionData, setTransactionData] = useState([]);
  useEffect(() => {
    const getDepositTransaction = async () => {
      const res = await getTransactions("", { limit: 20, _id: user.id });
      if (res.status === 201) {
        setTransactionData(res?.data?.transaction?.results || []);
      }
    };
    getDepositTransaction();
  }, [user.id]);

  // const handleWithClose = () => setWithdraw(false);

  return (
    <div className="wallet-balance-menu">
      <div className="wallet-balance">
        <h1>Wallet Balance</h1>
        <div className="wallet-balance-coin">
          <img src={coin} alt="" />
          <p>{changeCurrencyFormat(user?.coin || 0)}</p>
        </div>
        <div className="wallet-balance-coin-convert">
          <span>1 COIN = 1 USD</span>
          <span>1 COIN = 0,000047 BTC</span>
        </div>
        <div className="wallet-balance-bitcoin">
          <p>Bitcoin:</p>
          <span>BTC {multiplyValue(user?.coin, 0.000047)}</span>
        </div>
        <div className="wallet-balance-bitcoin">
          <p>USD:</p>
          <span>${changeCurrencyFormat(multiplyValue(user?.coin, 1))}</span>
        </div>
        <div className="wallet-balance-btn">
          <Button className="red-btn" onClick={handleShow}>
            Deposit
          </Button>
          <Button className="white-btn" onClick={handleWithShow}>
            Withdraw
          </Button>
        </div>
      </div>
      <div className="wallet-transaction">
        <div className="wallet-transaction-recent">
          <p>Recent Transactions</p>

          <span>
            <Link to="/transaction">Show All</Link>
          </span>
        </div>
        <div className="wallet-transaction-card">
          <div className="wallet-transaction-card-scroll">
            {transactionData.length > 0 ? (
              <>
                {transactionData.map((transaction) => (
                  <div
                    className="transaction-card"
                    key={transaction?.id ?? transaction?._id}
                  >
                    <div className="transaction-card-img">
                      <img
                        src={transaction.status === "accept" ? green : red}
                        alt=""
                      />
                    </div>
                    <div className="transaction-card-text">
                      <div className="transaction-card-left">
                        <p>{transaction?.transaction}</p>
                        <span>
                          {getDate(transaction.updatedAt)} at{" "}
                          {getTime(transaction.updatedAt)}
                        </span>
                      </div>
                      <div className="transaction-card-right">
                        <p>
                          {transaction.payment === "btc"
                            ? `+${transaction.cryptoPrice} BTC`
                            : `+${transaction.amountInUsd}`}
                        </p>
                        <span>
                          +{" "}
                          {transaction.payment === "btc"
                            ? multiplyValue(
                                transaction?.cryptoPrice,
                                0.000047
                              ).toFixed(10) || 0
                            : multiplyValue(transaction?.amountInUsd, 1) || 0}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <NoData />
            )}
          </div>
        </div>
      </div>
      <DepositPopup
        deposit={deposit}
        handleClose={handleClose}
        setDeposit={setDeposit}
      />
      <WithdrawPopup
        withdraw={withdraw}
        handleWithShow={handleWithShow}
        setWithdraw={setWithdraw}
      />
    </div>
  );
};

export default WalletMenu;
