import { useState, useContext, useEffect } from "react";
import { socket } from "../../config/socket";
import { Modal, Dropdown, Navbar, Nav } from "react-bootstrap";
import logo from "../../assets/images/header/logo3d.png";
import usericon from "../../assets/images/header/user1.png";
import coinicon from "../../assets/images/header/coin-icon.svg";
import UserContext from "../../context/UserContext";
import toast from "react-hot-toast";
import { Link, useNavigate, NavLink } from "react-router-dom";
import SearchBoxComponent from "../search/searchBoxComponent.jsx";
import "./header.css";
import MailSentPopup from "../register/mailSentPopup";
import VerifyEmail from "../login/verifyEmail";
import { verifyEmail } from "../../utils/Api";
import Notification from "../Notification/index";
import { changeCurrencyFormat } from "../../utils";
import NotificationIcon from "./NotificationIcon";

const Header = () => {
  const { user, setUser, setTokens,notifications,setNotifications } = useContext(UserContext);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [showBellNotify,setBellNotify] =useState(false)
  const handleModal = (e) => {
    setShow(e);
  };
  const handleNotification = () => {
    setShowNotification(!showNotification);
  };
  const urlParams = new URLSearchParams(window.location.search);
  const tokenFromParams = urlParams.get("token");
  const modalFromParams = urlParams.get("modal");
  const checkVerifyModalShow =
    modalFromParams && tokenFromParams ? true : false;
  const handleLogout = () => {
    localStorage.removeItem("token");
    setUser(null);
    setTokens({});
    toast.success("Logout Successfully");
    navigate("/login");
  };

  const verifyUserByMail = async (token) => {
    if (tokenFromParams) {
      const res = await verifyEmail(token);
      if (res.code === 200) {
        setShow(modalFromParams);
        setTimeout(function () {
          navigate("/login");
        }, 1500);
      }
    }
  };
  let token = null
  token = localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')) : null

  useEffect(() => {
    if (checkVerifyModalShow) {
      verifyUserByMail(tokenFromParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(()=>{
    socket.on("newNotificationClient", (data) => {
      try {
        const notificationsCopy = [...notifications];
        notificationsCopy.unshift(data.notification);
        setNotifications(notificationsCopy);
      } catch (error) {
        console.log(error)
      }
    })
  },[notifications, setNotifications])

  useEffect(()=>{
    const findIsReadExist = notifications.findIndex((el) => !el.isRead);
    if(findIsReadExist > -1){
      setBellNotify(true)
    }
  },[notifications])
  return (
    <>
      <div className='header-wrapper'>
        {!user  && <>
          <div className='top-header'>
          <div className='container'>
            <Link to='/register'>
              Create an account today and <span>get $5</span>
            </Link>
          </div>
        </div>
        </>}
        <div className='header'>
          <div className='container'>
            <div className='header-grid'>
              <Link to='/' className='logo'>
                <img src={logo} alt='' />
              </Link>
              <div className='header-menu-section'>
                <SearchBoxComponent />

                <div className='header-menu'>
                  <Navbar expand='lg'>
                    <Navbar.Toggle aria-controls='basic-navbar-nav' />
                    <Navbar.Collapse id='responsive-navbar-nav'>
                      <Nav>
                        <NavLink className='nav-link' to='/'>
                          Home
                        </NavLink>
                        <NavLink className='nav-link' to='/games'>
                          Games
                        </NavLink>
                        <NavLink className='nav-link' to='/offers'>
                          Offers
                        </NavLink>
                        <NavLink className='nav-link' to='/about'>
                          About
                        </NavLink>
                      </Nav>
                    </Navbar.Collapse>
                  </Navbar>
                </div>
              </div>
              {user && token? (
                <div className='user-loggedin'>
                  <NotificationIcon handleNotification={handleNotification} showBellNotify={showBellNotify}/>
                  <span className='wallet-section'>
                    <img src={coinicon} alt='logout' />{" "}
                    {changeCurrencyFormat(user?.coin || 0)}
                  </span>
                  <div className='user-pic'>
                    <Dropdown>
                      <Dropdown.Toggle id='dropdown-basic'>
                        <img src={user?.avatar??usericon} alt={user.name} />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item eventKey='logout' onClick={handleLogout}>
                          Logout
                        </Dropdown.Item>
                        <Dropdown.Item as={Link} to='/client-profile'>
                          Visit profile
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              ) : (
                <div className='auth-box'>
                  <Link to='/login'>Login</Link>
                  <Link to='/register'>Register</Link>
                </div>
              )}
            </div>
          </div>
          {showNotification && (
            <Notification
              showNotification={showNotification}
              handleNotification={handleNotification}
            />
          )}

          <Modal
            centered
            className='login-popup'
            show={show === "verifying"}
            onHide={() => handleModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <MailSentPopup
                handleModal={handleModal}
                text={
                  checkVerifyModalShow
                    ? "Your account has been verified"
                    : "Verification link has been send to your mail"
                }
                checkModalType={checkVerifyModalShow}
              />
            </Modal.Body>
          </Modal>
          <Modal
            centered
            className='login-popup'
            show={show === "verify-email"}
            onHide={() => handleModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <VerifyEmail handleModal={handleModal} />
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default Header;


