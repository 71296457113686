import { Link } from "react-router-dom";

const BrandBreadcrumb = ({data}) => {
  return (
    <div className="brand-breadcrumb">
      <ul>
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link to="/games">Games</Link>
        </li>
        <li>
          <Link to="/">{data?.name}</Link>
        </li>
      </ul>
    </div>
  );
};
export default BrandBreadcrumb;
