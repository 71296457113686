import { useState ,useContext} from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { yupResolver } from "@hookform/resolvers/yup";
import { DistributorSchema } from "../../validationSchema/authSchema";
import { PostDistributorDataApi } from "../../utils/Api";
import UserContext from "../../context/UserContext";

const DistributorSection = () => {
  const {user} = useContext(UserContext);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const handleDistributorPopup = () => {
    if (localStorage.getItem("token")) {
      setShow(!show);
    } else {
      navigate("/login");
    }
  };

  const {
    handleSubmit,
    register,
    setError,
    formState: { errors },
    reset,
    setValue
  } = useForm({ resolver: yupResolver(DistributorSchema) });
   setValue('email',user?.email);

  const handleDistributors = async (values) => {
    setIsLoading(true);
    const res = await PostDistributorDataApi(values);
    setIsLoading(false);
    if (res.status === 201) {
      reset();
      setShow(false);
      toast.success("You query is submitted. We will connect you soon.", {
        duration: 3000,
      });
      //navigate("/");
    } else {
      if (res.message === '"email" must be a valid email') {
        setError(
          "email",
          { type: "custom", message: "must be a valid email" },
          { shouldFocus: true }
        );
      }
    }
  };

  return (
    <div className="distributor-section">
      <div className="distributor-top-section">
        <div className="container">
          <div className="section-title">
            <h6>For games destributors</h6>
          </div>
          <div className="manage-sytem">
            <h2>Easy to Operate Management System</h2>
            <p>
              We’ve got free play, bonuses, community prizes, multipliers,
              lasers, and many extra features.
            </p>
            <div className="key-points-grid">
              <ul>
                <li>Account levels are clear and easy to manage</li>
                <li>Special attention to security and stability</li>
                <li>
                  Interface, automatically adapts to various mobile devices
                </li>
                <li>Simple to operate, practical</li>
                <li>
                  Distributors get to control the game list to keep the players
                  feeling new and excited, easy advertising
                </li>
              </ul>
            </div>
          </div>
          <div className="become-distributor-section">
            <div className="already-distributor">
              <p>
                If you are a distributor and want to log in to experience or
                customize your system, please
                <Link to="/contact"> contact us </Link>
              </p>
            </div>
            <div className="become-distributor-content">
              <div className="become-distributor-banner">
                <h2>Want to be a King of Fish Games distributor?</h2>
                <p>To start, with $5000, get the Minimum Order Quantity</p>
                {(user?.role ==='user' && user?.kycCompleted )&& <Button onClick={handleDistributorPopup}>Contact us</Button>}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        centered
        className="distributor-popup"
        show={show}
        size="xl"
        onHide={() => handleDistributorPopup()}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="distributor-popup-content">
            <h6>Became game distributor </h6>
            <p>Fill the form below and out managers will write you back asap</p>
            <div className="distributor-popup-form">
              <Form onSubmit={handleSubmit(handleDistributors)}>
                <Form.Group className="form-group">
                  <Form.Label>Company name</Form.Label>
                  <Form.Control
                    type="text"
                    name="companyName"
                    className={errors.companyName ? "error-field" : ""}
                    autoComplete="off"
                    {...register("companyName")}
                  />
                  {errors?.companyName ? (
                    <p className="error-text">{errors.companyName.message}</p>
                  ) : (
                    ""
                  )}
                </Form.Group>

                <Form.Group className="form-group">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="text"
                    name="email"
                    className={errors.email ? "error-field" : ""}
                    autoComplete="off"
                    defaultValue={user?.email}
                    disabled
                    {...register("email")}
                  />
                  {errors?.email ? (
                    <p className="error-text">{errors.email.message}</p>
                  ) : (
                    ""
                  )}
                </Form.Group>
                <Form.Group className="form-group">
                  <Form.Label>Phone number</Form.Label>
                  <Form.Control
                    type="number"
                    name="phoneNumber"
                    className={errors.phoneNumber ? "error-field" : ""}
                    autoComplete="off"
                    {...register("phoneNumber")}
                  />
                  {errors?.phoneNumber ? (
                    <p className="error-text">{errors.phoneNumber.message}</p>
                  ) : (
                    ""
                  )}
                </Form.Group>
                <Form.Group className="form-group">
                  <Form.Label>Number of games</Form.Label>
                  <Form.Control
                    type="number"
                    name="numberOfGames"
                    className={errors.numberOfGames ? "error-field" : ""}
                    autoComplete="off"
                    step="1"
                    {...register("numberOfGames")}
                  />
                  {errors?.numberOfGames ? (
                    <p className="error-text">{errors.numberOfGames.message}</p>
                  ) : (
                    ""
                  )}
                </Form.Group>

                <Form.Group className="form-group">
                  <Form.Label>Message</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="message"
                    className={errors.message ? "error-field" : ""}
                    autoComplete="off"
                    {...register("message")}
                  />
                  {errors?.message ? (
                    <p className="error-text">{errors.message.message}</p>
                  ) : (
                    ""
                  )}
                </Form.Group>
                <Button className="s-btn" type="submit">
                  {isLoading ? <Spinner animation="border" /> : "Send"}
                </Button>
              </Form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default DistributorSection;
