import axios from "axios";
import { getToken } from "../config/axios";
import { createRecentGame } from "./Api";

// 1 - function related to date and time
function getMonthName(month) {
  const d = new Date();
  d.setMonth(month);
  const monthName = d.toLocaleString("default", { month: "long" });
  return monthName;
}
export const getDate = (d) => {
  const date = new Date(d);
  const day = date.getDate(); // Date of the month: 2 in our example
  let month = date.getMonth(); // Month of the Year: 0-based index, so 1 in our example
  const year = date.getFullYear(); // Year: 2013
  //const weekday = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  //const dayofweek = weekday[date.getDay()];
  month = getMonthName(month);
  return `${day} ${month} ${year}`;
};

export const getTime = (date) => {
  let dateChange = date ? new Date(date) : new Date();
  let time = dateChange.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
  return time;
};

export const  getAge = (dateString)=> {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
  }
  return age;
}

export const timeGreet =()=>{
  var myDate = new Date();
var hrs = myDate.getHours();

var greet;

if (hrs < 12)
greet = 'Good Morning';
else if (hrs >= 12 && hrs <= 16)
greet = 'Good Afternoon';
else if (hrs >= 17 && hrs <= 24)
greet = 'Good Evening';

return greet
}

//2- Function related to crypto
export const getBlockchainPrice = async (blockchain, currency) => {
  try {
    const symbol = blockchain + currency;
    const response = await axios.get(
      `https://api.binance.com/api/v3/ticker/price?symbol=${symbol.toUpperCase()}`
    );
    const { price } = response.data;
    return parseFloat(price);
  } catch (error) {
    console.log("error in fiat rate---", error);
    return 0;
  }
};

export const convertCurrency = async (blockchain, currency, userCoinPrice) => {
  const resultAmount = await getBlockchainPrice(blockchain, currency);
  const curPrice = resultAmount;
  const totalAmount = userCoinPrice * curPrice;
  const totalAmountToRender = totalAmount.toFixed(2);
  return totalAmountToRender;
};

export const errorMsgTimeout = 1500;

//3- function related to games
export const openGameLink = async (downlaodUrl,gameId,navigate) => {
  const token = getToken();
  if (token) {
    if(gameId){
      const res = await createRecentGame(gameId);
      if(res.status ===201){
        let link = downlaodUrl;
        if (!link.includes("https")) {
          link = `https://${link}`;
        }
        window.open(link, "_blank");
      }
    }
  } else {
    navigate('/login')
  }
};

//4- Function relataed to change string
export const capitalizeFirstLetter = (string) => {
  if(!string){
    return ''
  }
  if (string.toLowerCase() === "pc") {
    return string.toUpperCase();
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getLevelStatus = (amount) => {
  let string = "New Player";
  if (amount > 1 && amount < 8000) string = "Loyal Player";
  if (amount > 8000) string = "Super Loyal Player";
  return string;
};

export const showUsername = (userObj) => {
  let str = userObj.name;
  if ("firstname" in userObj && "lastname" in userObj) {
    if (userObj.firstname && userObj.lastname) {
      str = `${userObj.firstname} ${userObj.lastname}`;
    }
  }
  return str;
};

export const changeStatusValue = (string)=>{
  if(!string){
    return ''
  }
  if(string==='accept'){
    string = 'Confirmed'
  } else if(string==='reject'){
    string = 'Cancelled'
  }
  return string
}

// copy string
export const copyText =(string)=>{
  navigator.clipboard.writeText(string)
}

export const changeCurrencyFormat = (number )=>{
  number =number.toString()
  number = number.split('').reverse().join('').replace(/([0-9]{3})/g, "$1 ").split('').reverse().join('');
  return number
}

// function realted to maths and stats

export const getPercentage = (amount, totalAmount) => {
  return (amount / totalAmount) * 100;
};

export const getLevel = (amount) => {
  const obj = {
    amountNeed: 1,
    levelOnePer: 0,
    levelTwoPer: 0,
    levelThreePer: 0,
    levelOneAmount: 0,
    levelTwoAmount: 0,
    levelThreeAmount: 0,
    totalAmount: amount,
    circularBarPer: 0,
  };
  if (amount - 1 >= 0) {
    obj.levelOnePer = 100;
    obj.levelOneAmount = 1;
    if (amount - 1000 >= 0) {
      obj.levelTwoPer = 100;
      obj.levelTwoAmount = 1000;
      if (amount - 8000 >= 0) {
        obj.levelThreePer = 100;
        obj.levelThreeAmount = 8000;
        obj.amountNeed = 0
        obj.circularBarPer = 100
      } else {
        obj.amountNeed = 8000 - amount;
        obj.levelThreePer = getPercentage(amount, 8000);
        obj.levelThreeAmount = amount;
        obj.circularBarPer = obj.levelThreePer;
      }
    } else {
      obj.amountNeed = 1000 - amount;
      obj.levelTwoPer = getPercentage(amount, 1000);
      obj.levelTwoAmount = amount;
      obj.circularBarPer = obj.levelTwoPer;
    }
  } else {
    obj.levelOnePer = 0;
    obj.amountNeed = 1;
    obj.circularBarPer = 0;
  }
  return obj;
};
