import { Link, useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import "./home.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import slide2 from "../../assets/images/home/fire.png";
import info from "../../assets/images/home/info.svg";

import { openGameLink } from "../../utils";

const HeroSlider = ({games}) => {
  const navigate = useNavigate()
  const settings = {
    dots: true,
    cssEase: "linear",
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          autoplay: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          autoplay: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          autoplay: true,
        },
      },
    ],
  };
  

  return (
    <div className='hero-slider'>
      <div className='container'>
        <div className='hero-slider-content'>
          <Slider {...settings}>
            {games.length > 0 &&
              games.map((el) => (
                <div>
                  <div className='hero-slider-tiles' key={el?.id ?? el?._id}>
                    <div className='hero-slider-box'>
                      <div className='hero-slider-grid'>
                        <div className='hero-slider-pic'>
                          <img src={el?.logo|| slide2} alt='slider-pic' />
                        </div>
                        <div className='hero-slider-info'>
                          <h6>{el?.name||''}</h6>
                          <div className='hero-slider-btn'>
                            <Button onClick={()=>openGameLink(el?.downloadUrl,el?.id,navigate)} className='download-btn'>Download</Button>
                            <Link to={`/download?game=${el.id}`}>
                            <Button className='info-btn' >
                              <img src={info} alt='info-icon' />
                            </Button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default HeroSlider;
