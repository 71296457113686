import { Button, Modal } from "react-bootstrap";
import game from "../../../assets/images/wallet/popopimg.svg";
import remote from "../../../assets/images/wallet/gameremote.svg";

import "./coinpopup.css";

const CoinPopup = ({ handleClose, coin }) => {
  return (
    <div className='coin-popup-wrapper'>
      <Modal
        size='lg'
        className='coin-popup'
        show={coin}
        onHide={() => handleClose()}
        centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className='coin-popup-content'>
            <h1>Not enough coins</h1>
            <p>
              There is not enough coins on your wallet to play this game, please
              deposit money to your wallet
            </p>
            <div className='game-box'>
              <div className='game-box-left'>
                <img src={game} alt='' />
              </div>
              <div className='game-box-right'>
                <p>Ultra monsters</p>
                <div className='coin-game-box-img'>
                  <img src={remote} alt='' />
                  <span>125 Games</span>
                </div>
              </div>
            </div>
            <div className='game-box-btn'>
              <div className='transparent-btn'>
                <Button onClick={handleClose}>cancel</Button>
              </div>
              <div className='red-btn'>
                <Button>Deposit</Button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CoinPopup;
