import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./home.css";
import user1 from "../../assets/images/home/user1.png";
import user2 from "../../assets/images/home/user2.png";
import user3 from "../../assets/images/home/user3.png";
import star from "../../assets/images/home/star.svg";

const Testimonial = () => {
  const settings = {
    dots: false,
    cssEase: "linear",
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          autoplay: true,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
          autoplay: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          autoplay: true,
        },
      },
    ],
  };
  return (
    <div className="testimonial-section">
      <div className="container">
        <div className="section-title">
          <h6>Reviews</h6>
        </div>
        <div className="testimonial-content">
          <div className="testimonial-heading">
            <h2>Here is what our customers are saying</h2>
            <p>
            Listen to the actual experience from our customers.
            </p>
          </div>
          <div className="testimonial-slider">
            <Slider {...settings}>
              <div className="testimonial-tiles">
                <div className="testimonial-box">
                  <div className="user-info-grid">
                    <div className="user-img-box">
                      <img src={user2} alt="user-pic" />
                      <h6>Mark Johnson</h6>
                    </div>
                    <div className="star-grid">
                      <img src={star} alt="star" />
                      <img src={star} alt="star" />
                      <img src={star} alt="star" />
                      <img src={star} alt="star" />
                      <img src={star} alt="star" />
                    </div>
                  </div>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud{" "}
                  </p>
                </div>
              </div>

              <div className="testimonial-tiles">
                <div className="testimonial-box">
                  <div className="user-info-grid">
                    <div className="user-img-box">
                      <img src={user2} alt="user-pic" />
                      <h6>Mark Johnson</h6>
                    </div>
                    <div className="star-grid">
                      <img src={star} alt="star" />
                      <img src={star} alt="star" />
                      <img src={star} alt="star" />
                      <img src={star} alt="star" />
                      <img src={star} alt="star" />
                    </div>
                  </div>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud{" "}
                  </p>
                </div>
              </div>

              <div className="testimonial-tiles">
                <div className="testimonial-box">
                  <div className="user-info-grid">
                    <div className="user-img-box">
                      <img src={user3} alt="user-pic" />
                      <h6>Alexander Smith</h6>
                    </div>
                    <div className="star-grid">
                      <img src={star} alt="star" />
                      <img src={star} alt="star" />
                      <img src={star} alt="star" />
                      <img src={star} alt="star" />
                      <img src={star} alt="star" />
                    </div>
                  </div>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud{" "}
                  </p>
                </div>
              </div>
              <div className="testimonial-tiles">
                <div className="testimonial-box">
                  <div className="user-info-grid">
                    <div className="user-img-box">
                      <img src={user1} alt="user-pic" />
                      <h6>John Snow</h6>
                    </div>
                    <div className="star-grid">
                      <img src={star} alt="star" />
                      <img src={star} alt="star" />
                      <img src={star} alt="star" />
                      <img src={star} alt="star" />
                      <img src={star} alt="star" />
                    </div>
                  </div>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud{" "}
                  </p>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
