import { useState, useEffect,useContext } from "react";
import SearchBoxComponent from "../search/searchBoxComponent";
import { Button, Modal } from "react-bootstrap";
import "./game-redeem.css";
import game from "../../../assets/images/wallet/game.svg";
import remote from "../../../assets/images/wallet/gameremote.svg";
import ClientLayout from "../clientlayout/clientlayout";
import PageHeader from "../pageheader/pageheader";
import thumbiocn from "../../../assets/images/offer/thumb-icon.svg";
import coinicon from "../../../assets/images/header/coin-icon.svg";
import UserContext from "../../../context/UserContext";
import { createRecharge, getGames } from "../../../utils/Api";
import TableLoader from "../../table_loader/tableloader";
import NoData from "../../noData";

const GameRedeem = () => {
  const [redeem, setRedeem] = useState(false);
  const [redeemSuccess, setRedeemSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleRedeem = (data) => {
    setSelectedGame(data);
    setRedeem(!redeem);
  };
  const handleRedeemSuccess = () => {
    setRedeem(false);
    setRedeemSuccess(!redeemSuccess);
  };
  const [games, setGames] = useState([]);
  const [selectedGame, setSelectedGame] = useState(null);
  const [redeemInput, setRedeemInput] = useState("0");

  useEffect(() => {
    const getAllGames = async () => {
      setLoading(true);
      const res = await getGames();
      setLoading(false);
      if (res.status === 200) {
        setGames(res.data.game.results);
      }
    };
    getAllGames();
  }, []);

  return (
    <ClientLayout>
      <div className='game-redeem-page'>
        <PageHeader pageTitle='Game Redeem' btnTitle='Withdraw money' />
        <div className='container'>
          <div className='admin-content-wrapper'>
            <div className='redeemed-game'>
              <SearchBoxComponent />
              {!loading ? (
                <div className='redeemed-game-content'>
                  <div className='wallet-user-recent-game-grid'>
                    {games.length > 0 ? (
                      <>
                        {games.map(({ id, name, playCount, logo }) => (
                          <div className='recent-game_box' key={id}>
                            <img src={logo || game} alt='' />
                            <div className='recent-game-detail'>
                              <div className='recent-game-detail-bottom'>
                                <div className='recent-game-detail-left'>
                                  <p>{name || ""}</p>
                                  <div className='recent-game-detail-img'>
                                    <img src={remote} alt='' />
                                    <span>{playCount || 0}</span>
                                  </div>
                                </div>
                                <div className='recent-game-detail-right'>
                                  <Button
                                    className='red-btn'
                                    onClick={() =>
                                      handleRedeem({
                                        name,
                                        playCount,
                                        logo,
                                        id,
                                      })
                                    }>
                                    Redeem
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </>
                    ) : (
                      <NoData heading='No game Redeem Data' />
                    )}
                  </div>
                </div>
              ) : (
                <TableLoader />
              )}
            </div>
          </div>
        </div>
        <Redeem
          redeem={redeem}
          handleRedeem={handleRedeem}
          handleRedeemSuccess={handleRedeemSuccess}
          selectedGame={selectedGame}
          redeemInput={redeemInput}
          setRedeemInput={setRedeemInput}
        />
        {redeemSuccess &&
        <RedeemSuccess
          handleRedeemSuccess={handleRedeemSuccess}
          redeemSuccess={redeemSuccess}
          selectedGame={selectedGame}
          redeemInput={redeemInput}
          setRedeemInput={setRedeemInput}
        />}
      </div>
    </ClientLayout>
  );
};

export default GameRedeem;

const Redeem = ({
  redeem,
  handleRedeem,
  handleRedeemSuccess,
  selectedGame,
  redeemInput,
  setRedeemInput,
}) => {
  const {user} = useContext(UserContext);
  const { name, playCount, logo, id } = selectedGame || {};
  const [error, setError] = useState("");
  const [successMsg, setSuccessMsg] = useState("");

  const handleRedeemClick = async () => {
    const redeemCoin = parseInt(redeemInput||0); // Dynamic redeeem coin value come here
    if (redeemCoin <= 0) {
      setError("Coins must be more than 0");
    } else if(redeemCoin > user.coin){
      setError('The amount input must be equal or less than the amount in your platform’s account.')
    } else {
      const payload = {
        coin: redeemCoin,
        payment: "coin",
        gameId: id,
      };
      const res = await createRecharge("redeem", payload);
      if (res.status === 201) {
        setSuccessMsg("Request sent successfully.");
        setError("");
        setTimeout(() => {
          handleRedeemSuccess();
          setSuccessMsg("");
          
        }, 500);
      } else {
        setError(res.message || "Unable to process request");
      }
    }
  };

  const hidePopup = () => {
    handleRedeem();
    setRedeemInput("");
    setError("");
    setSuccessMsg("");
  };
  return (
    <Modal
      centered
      className='process-popup'
      show={redeem}
      size='lg'
      onHide={() => hidePopup()}>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className='redeem-popup-content'>
          <h5>Redeem game </h5>
          <div className='game-recharge-info'>
            <div className='game-recharge-left'>
              <img src={logo || game} alt='' />
            </div>
            <div className='game-recharge-right'>
              <h6> {name || ""}</h6>
              <p>
                <img src={remote} alt='' />
                {playCount || 0}
              </p>
            </div>
          </div>
          <div className='total-balance'>
            <h6>Available for Redeem</h6>
            <div className='deposit-popup-content'>
              <div className='deposit-popup-input'>
                <input
                  type='number'
                  value={redeemInput.coin}
                  name='coin'
                  placeholder='Amount of coins you want to get'
                  className='form-control'
                  min='1'
                  step='1'
                  onKeyDown={(event) => {
                    if (event.key === ".") {
                      event.preventDefault();
                    }
                  }}
                  onChange={(e) => setRedeemInput(e.target.value)}
                />
                <div className='deposit-popup-money'>
                  <img src={coinicon} alt='' />
                  <span>{redeemInput || 0}</span>
                </div>
              </div>
            </div>
          </div>
          {error && <p className='text-danger'>{error}</p>}
          {successMsg && <p className='text-success'>{successMsg}</p>}
          <div className='popup-footer-btn'>
            <Button className='popup-black' onClick={hidePopup}>
              cancel
            </Button>
            <Button className='popup-red' onClick={() => handleRedeemClick()}>
              Confirm
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const RedeemSuccess = ({
  redeemSuccess,
  handleRedeemSuccess,
  selectedGame,
  redeemInput,
  setRedeemInput,
}) => {
  const { name, logo } = selectedGame || {};
  const handleClose = ()=>{
    handleRedeemSuccess()
    setRedeemInput('')
  }
  return (
    <Modal
      centered
      className='process-popup'
      show={redeemSuccess}
      size='lg'
      onHide={() => handleClose()}>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className='redeem-success-content'>
          <img src={thumbiocn} alt='icon' className='thumb-icon' />
          <h4>Your game redeem request is sent</h4>
          <h5>We will notify you when your request is completed</h5>
          <div className='game-recharge-info'>
            <div className='game-recharge-left'>
              <img src={logo || game} alt='' />
            </div>
            <div className='game-recharge-right'>
              <h6>
                {name || ""} <span>By Milky Way</span>
              </h6>
              <p>
                {/* here amount of games will came */}
                <img src={coinicon} alt='icon' />
               {redeemInput}
              </p>
            </div>
          </div>
          <Button className='popup-red' onClick={handleClose}>
            Ok
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
