import { Link } from "react-router-dom";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Form, Button, Spinner } from "react-bootstrap";
import PageTitle from "../home/pageTitle";
import Layout from "../layout/layout";
import contactIcon from "../../assets/images/footer/contact-icon.svg";
import mail from "../../assets/images/footer/mail.svg";
import facebook from "../../assets/images/footer/facebook.svg";
import youtube from "../../assets/images/footer/youtube.svg";
import telegram from "../../assets/images/footer/telegram.svg";
import { yupResolver } from "@hookform/resolvers/yup";
import { ContactsSchema } from "../../validationSchema/authSchema";
import { contactUsApi } from "../../utils/Api";
import toast from "react-hot-toast";
import "./footer.css";

const ContactUs = () => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    handleSubmit,
    register,
    // setError,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(ContactsSchema) });

  const handleContactus = async (values) => {
    setIsLoading(true);
    const res = await contactUsApi(values);

    setIsLoading(false);
    if (res.status === 201) {
      reset();
      toast.success("You query is submitted. We will connect you soon.", {
        duration: 3000,
      });
      //navigate("/");
    }
  };

  return (
    <Layout>
      <div className="contact-page">
        <div className="container">
          <div className="contact-grid">
            <div className="contact-left">
              <PageTitle pageTitle="Contact us" titleIcon={contactIcon} />
              <div className="get-in-touch">
                <h1>Get in touch</h1>
                <p>
                  If you hane any questions please send us a message. Our
                  managers will write you back as soon as possible.
                </p>
                <a href="mailto:kingoffish@gmail.com">
                  <img src={mail} alt="" /> kingoffish@gmail.com
                </a>
              </div>
              <div className="follow-us">
                <h6>Follow us in Social media:</h6>
                <ul>
                  <li>
                    <Link to="#">
                      <img src={facebook} alt="" /> Facebook
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <img src={youtube} alt="" /> Youtube
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <img src={telegram} alt="" /> Telegram
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="contact-right">
              <div className="contact-box">
                <Form onSubmit={handleSubmit(handleContactus)}>
                  <Form.Group className="form-group">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      className={errors.name ? "error-field" : ""}
                      autoComplete="off"
                      {...register("name")}
                    />
                    {errors?.name ? (
                      <p className="error-text">{errors.name.message}</p>
                    ) : (
                      ""
                    )}
                  </Form.Group>

                  <Form.Group className="form-group">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="text"
                      name="email"
                      className={errors.email ? "error-field" : ""}
                      autoComplete="off"
                      {...register("email")}
                    />
                    {errors?.email ? (
                      <p className="error-text">{errors.email.message}</p>
                    ) : (
                      ""
                    )}
                  </Form.Group>

                  <Form.Group className="form-group">
                    <Form.Label>Question</Form.Label>
                    <Form.Control
                      as="textarea"
                      name="message"
                      className={errors.message ? "error-field" : ""}
                      autoComplete="off"
                      {...register("message")}
                    />
                    {errors?.message ? (
                      <p className="error-text">{errors.message.message}</p>
                    ) : (
                      ""
                    )}
                    <span className="max-limit">0 / 200</span>
                  </Form.Group>

                  <Button className="s-btn" type="submit">
                    {isLoading ? <Spinner animation="border" /> : "Send"}
                  </Button>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ContactUs;
