import { Button, Modal } from "react-bootstrap";
import thumbiocn from "../../../assets/images/offer/thumb-icon.svg";

const WithdrawRequest = ({ withDrawReq, closePopup ,withdrawData}) => {
  return (
    <Modal
      centered
      className="process-popup"
      show={withDrawReq}
      size="lg"
      onHide={() => closePopup()}
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="with-req-pop recharge-success-content">
          <img src={thumbiocn} alt="icon" className="thumb-icon" />
          <h5>Your wallet balance withdrawal request is sent</h5>
          <p>We will notify you when your request is completed</p>
          {withdrawData.payment==='cashApp' ? <span>${withdrawData?.amountInUsd}</span> : <span>BTC {withdrawData?.cryptoPrice}</span>
}
          <Button className="popup-red" onClick={closePopup}>
            Ok
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default WithdrawRequest;
