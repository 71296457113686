const WarningSection = () => {
  return (
    <div className="warning-section">
      <div className="container">
        <p>
          Free Demo Accounts are not available to the player, thank you! Online
          Skill Solutions is the original developer of King of Fish Games and
          provides points of this APP to large distributors. Those distributors
          sell to smaller distributors, who then sell it to individual stores.
          King of Fish Games has no affiliation with these stores and is not
          responsible for how they run their business. However, King of Fish
          Games does want to know of wrongdoing, and will conduct an
          investigation on what has been reported. Skill King of Fish Games does
          not tolerate abuse of the software.{" "}
        </p>
      </div>
    </div>
  );
};

export default WarningSection;
