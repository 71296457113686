import React, { useState,useContext } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useNavigate } from "react-router-dom";
import { ResetPasswordSchema } from "../../validationSchema/authSchema";
import { resetPassword } from "../../utils/Api";
import Layout from "../layout/layout";
import toast from "react-hot-toast";
import UserContext from "../../context/UserContext";


const ResetPassword = () => {
  const resetPassEmailValue = localStorage.getItem('resetPassEmail');
  const {setTokens,setUser} = useContext(UserContext);
  const navigate = useNavigate();
  const [pass, setPass] = useState("password");
  const [confirmPass, setConfirmPass] = useState("password");
  const handlePass = () => {
    setPass(!pass);
  }
  const handleConfirmPass = () => {
    setConfirmPass(!confirmPass);
  };
  const [isLoading, setIsLoading] = useState(false);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ resolver: yupResolver(ResetPasswordSchema) });

  const handleResetPassword = async (values) => {
    setIsLoading(true);
    // const urlParams = new URLSearchParams(window.location.search)
    // const res = await resetPassword(values, urlParams.get("token"));
   const res = await resetPassword(values,resetPassEmailValue);
    setIsLoading(false);
    if (res.code === 200) {
      toast.success("Password update successfully.");
      localStorage.setItem("token", JSON.stringify(res.data.tokens));
      setTokens(res.data.tokens);
      setUser(res.data.user);
      localStorage.removeItem('resetPassEmail');
      setTimeout(() => {
        navigate("/");
      }, 1000);
    } else {
      toast.error(res.message);
    }
  };

  return (
    <Layout>
      <div className="reset-password-section">
        <div className="auth-page">
          <div className="forget-section">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="forget-form">
                    <h1>Create a new password</h1>

                    <div className="forget-box">
                      <Form onSubmit={handleSubmit(handleResetPassword)}>
                        <Form.Group className="form-group">
                          <Form.Label>Create a new password</Form.Label>
                          <Form.Control
                            type={`${pass ? `password` : `text`}`}
                            name="password"
                            className={errors.password ? "error-field" : ""}
                            {...register("password")}
                          /> 
                          <span className="input-icon" onClick={handlePass}>
                           <i class={pass ? "las la-eye" :'las la-eye-slash'}></i>
                          </span>
                          {errors?.password ? (
                            <p className="error-text">
                              {errors.password.message}
                            </p>
                          ) : (
                            ""
                          )}
                        </Form.Group>
                        <Form.Group className="form-group">
                          <Form.Label>Confirm the password</Form.Label>
                          <Form.Control
                            type={`${confirmPass ? `password` : `text`}`}
                            name="confirmPassword"
                            className={
                              errors.confirmPassword ? "error-field" : ""
                            }
                            {...register("confirmPassword")}
                          />
                          <span className="input-icon"  onClick={handleConfirmPass}>
                            <i class={confirmPass ? "las la-eye" :'las la-eye-slash'}></i>
                          </span>
                          {errors?.confirmPassword ? (
                            <p className="error-text">
                              {errors.confirmPassword.message}
                            </p>
                          ) : (
                            ""
                          )}
                        </Form.Group>
                        <div className="login-button">
                          <Button type="submit" className="l-btn">
                            {isLoading ? (
                              <Spinner animation="border" />
                            ) : (
                              "Create a new password"
                            )}
                          </Button>
                        </div>
                      </Form>
                      <p className="already">
                        <Link to="/login" className="forget-link">
                          Back to Login
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ResetPassword;
