import { io } from "socket.io-client";
import { server } from "./keys.js";

const token = localStorage.getItem("token")
  ? JSON.parse(localStorage.getItem("token"))?.access?.token
  : null;
// console.log({ token, server });
const socket = io(server, {
  reconnectionDelayMax: 1000,
  reconnection: true,
  transports: ["websocket", "xhr-polling"],
  rejectUnauthorized: false,
  query: `token=${token}`,
});

socket.on("connect", () => {
  console.log("connected");
  socket.emit("join", { userId: localStorage.getItem("userId") });
});

socket.on("reconnect", () => {
  if (token) {
    console.log("Reconnecting called");
    socket.emit("join", token);
  }
});

const tryReconnect = () => {
  setTimeout(() => {
    socket.io.open((err) => {
      if (err) {
        tryReconnect();
      }
    });
  }, 2000);
};

socket.io.on("close", tryReconnect);

export { socket };
