import {useContext} from 'react'
import PageTitle from "../home/pageTitle";
import aboutIcon from "../../assets/images/about/about-icon.svg";
import UserContext from "../../context/UserContext";


const AboutBanner = () => {
  const {landingPagesImg} = useContext(UserContext);
  return (
    <div className="about-banner" style={{background: `${landingPagesImg?.aboutPageImg ? `url(${landingPagesImg?.aboutPageImg})` : ''}`}}>
      <div className="container">
        <PageTitle pageTitle="About" titleIcon={aboutIcon} />

        <div className="banner-grid">
          <div className="banner-left">
            <h1>America’s Best Online Casino</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutBanner;
