import Layout from "../layout/layout";
import AboutBanner from "./about-banner";
import AboutFeature from "./about-feature";

import "./about.css";
import DistributorSection from "./games-distributor";
import WarningSection from "./warning";

const AboutUs = () => {
  return (
    <Layout>
      <div className="about-page">
        <AboutBanner />
        <AboutFeature />
        <DistributorSection />
        <WarningSection />
      </div>
    </Layout>
  );
};

export default AboutUs;
