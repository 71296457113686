import {  useEffect , useState,useCallback } from "react";
import SearchBoxComponent from "../search/searchBoxComponent";
import { Table, Tabs, Tab } from "react-bootstrap";
import cashicon from "../../../assets/images/transaction/cash-app.svg";
import cryptoicon from "../../../assets/images/transaction/crypto.svg";
import coinicon from "../../../assets/images/header/coin-icon.svg";
import "./transaction.css";
import PagePagination from "../../pagination/Pagination";
import ClientLayout from "../clientlayout/clientlayout";
import { getTransactions } from "../../../utils/Api";
import { getDate, getTime } from "../../../utils";
import { useContext } from "react";
import UserContext from "../../../context/UserContext";
import NoData from '../../noData/index'
import TableLoader from "../../table_loader/tableloader";

const Transaction = () => {
  const {user} = useContext(UserContext);
  const [transactions, setTransactions] = useState([]);
  const [transactionView, setTransactionView] = useState("deposit");
  const [loading,setLoading] = useState(false)

  const initialPaginationValue = {
    limit: 0,
    page: 1,
    totalPages: 0,
    totalResults: 0,
    _id: user.id,
    searchValue:''
  };
  const [paginationValue, setPaginationValue] = useState(
    initialPaginationValue
  );

  useEffect(() => {
    const getTrasactionsByType = async () => {
      setLoading(true)
      const res = await getTransactions(
        transactionView.toLowerCase(),
        paginationValue
      );
      setLoading(false)
      if (res.status === 201) {
        const { results, limit, page, totalPages, totalResults } =
          res?.data?.transaction;
        setTransactions(results || []);
        const pageData = { limit, page, totalPages, totalResults, _id: user.id,searchValue:'' };
        if (JSON.stringify(paginationValue) !== JSON.stringify(pageData)) {
          setPaginationValue(pageData);
        }
      }
    };
    getTrasactionsByType();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationValue?.page, transactionView, user?.id]);


  const handleTransactionView = (tab) => {
    setPaginationValue({ ...paginationValue, page: 1 });
    setTransactionView(tab);
  };
  const handlePagination = (pagevalue) => {
    const copypagination = { ...paginationValue };
    if (pagevalue <= copypagination.totalPages) {
      copypagination.page = pagevalue;
    }
    setPaginationValue(copypagination);
  };

  
  // const updatePaginate =(pageData)=>setPaginationValue(pageData)


  const handleSearchTransaction = useCallback (async (value) => {
    const copyPaginationValue = { ...paginationValue };
    copyPaginationValue.searchValue = value;
    const res = await getTransactions(
      transactionView.toLowerCase(),
      copyPaginationValue
    );
    const { results, limit, page, totalPages, totalResults } =
      res?.data?.transaction;
    setTransactions(results || []);
    const pageData = { limit, page,totalPages, totalResults,_id: user.id,searchValue:value};
    if (JSON.stringify(copyPaginationValue) !== JSON.stringify(pageData)) {
      setPaginationValue(pageData);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[paginationValue?.page, transactionView, user?.id]);

  return (
    <ClientLayout>
      <div className='transaction-page'>
        <div className='container'>
          <div className='admin-content-wrapper'>
            <div className='admin-section-title'>
              <h3>Transaction record</h3>
            </div>

            <div className='admin-tab-section'>
              <Tabs
                onSelect={(tab) => handleTransactionView(tab)}
                defaultActiveKey='deposit'
                id='uncontrolled-tab-example'>
                <Tab eventKey='deposit' title='Wallet deposit'>
                  <div className='deposit-record'>
                    <div className='row'>
                      <div className='col-md-12'>
                        <SearchBoxComponent
                          searchFunction={handleSearchTransaction}
                        />
                        {!loading ? <>
                          {transactions.length > 0 ? <>
                        
                        <div className='admin-table'>
                          <Table hover responsive>
                            <thead>
                              <tr>
                                <th>Payment</th>
                                <th>Date</th>
                                <th>Time</th>
                                <th>Status</th>
                                <th>Currency</th>
                                <th>Amount</th>
                                <th>Coins</th>
                              </tr>
                            </thead>
                            {transactions?.length > 0 ? (
                              <>
                                {transactions?.map((data, i) => (
                                  <DepositRow data={data} key={data?.id ?? data?._id}/>
                                ))}
                              </>
                            ) : (
                              ""
                            )}
                          </Table>
                        </div>
                        {transactions.length > 1 &&  <div className='client-pagination-sec'>
                          <PagePagination
                            page={paginationValue?.page}
                            totalpages={paginationValue?.totalPages}
                            handlePagination={handlePagination}
                          />
                        </div>}
                        </>: <NoData/>}</> : <TableLoader/>}
                       
                        
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey='withdraw' title='wallet withdraw'>
                  <div className='withdraw-record'>
                    <div className='row'>
                      <div className='col-md-12'>
                        <SearchBoxComponent searchFunction={handleSearchTransaction}/>
                        {!loading ? <>
                          {transactions.length > 0 ? <>
                        <div className='admin-table'>
                          <Table hover responsive>
                            <thead>
                              <tr>
                                <th>Payment</th>
                                <th>Date</th>
                                <th>Time</th>
                                <th>Status</th>
                                <th>Coins</th>
                                <th>Currency</th>
                                <th>Sum</th>
                              </tr>
                            </thead>

                            {transactions.length > 0 ? (
                              <>
                                {transactions.map((data, i) => (
                                  <WithdrawRow data={data} key={data?.id ?? data?._id}/>
                                ))}
                              </>
                            ) : (
                              ""
                            )}
                          </Table>
                        </div>
                        {transactions.length > 1 &&  <div className='client-pagination-sec'>
                          <PagePagination
                            page={paginationValue?.page}
                            totalpages={paginationValue?.totalPages}
                            handlePagination={handlePagination}
                          />
                        </div>}
                        </> : <NoData/>}
                        </> : <TableLoader/>}
                    
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey='redeem' title='Game redeem'>
                  <div className='redeem-record'>
                    <div className='row'>
                      <div className='col-md-12'>
                        <SearchBoxComponent searchFunction={handleSearchTransaction}/>
                        {!loading ? <>
                          {transactions.length > 0 ? <>
                        <div className='admin-table'>
                        <Table hover responsive>
                          <thead>
                            <tr>
                              <th>Game provider</th>
                              <th>Game id</th>
                              <th>Date</th>
                              <th>Time</th>
                              <th>Status</th>
                              <th>Coins</th>
                            </tr>
                          </thead>
                          {transactions.length > 0 ? (
                            <>
                              {transactions.map((data, i) => (
                                <RedeemRechargeRow data={data} key={data?.id ?? data?._id}/>
                              ))}
                            </>
                          ) : (
                            ""
                          )}
                        </Table>
                      </div>
                      {transactions.length > 1 &&  <div className='client-pagination-sec'>
                          <PagePagination
                            page={paginationValue?.page}
                            totalpages={paginationValue?.totalPages}
                            handlePagination={handlePagination}
                          />
                        </div>}
                        </>: <NoData/>}
                        </> : <TableLoader/>}
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey='recharge' title='Game recharge'>
                  <div className='recharge-record'>
                    <div className='row'>
                      <div className='col-md-12'>
                        <SearchBoxComponent searchFunction={handleSearchTransaction}/>
                        {!loading ? <>
                          {transactions.length > 0 ? <>
                        <div className='admin-table'>
                          <Table hover responsive>
                            <thead>
                              <tr>
                                <th>Game provider</th>
                                <th>Game id</th>
                                <th>Date</th>
                                <th>Time</th>
                                <th>Status</th>
                                <th>Coins</th>
                              </tr>
                            </thead>
                            {transactions.length > 0 ? (
                              <>
                                {transactions.map((data, i) => (
                                  <RedeemRechargeRow data={data} key={data?.id ?? data?._id}/>
                                ))}
                              </>
                            ) : (
                              ""
                            )}
                          </Table>
                        </div>
                        {transactions.length > 1 &&  <div className='client-pagination-sec'>
                          <PagePagination
                            page={paginationValue?.page}
                            totalpages={paginationValue?.totalPages}
                            handlePagination={handlePagination}
                          />
                        </div>}
                       
                        </>:<NoData/>}
                        </>: <TableLoader/>}
                      
                      </div>
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </ClientLayout>
  );
};

export default Transaction;

const DepositRow = ({
  data: { payment, status, updatedAt, cryptoPrice, amountInUsd, coin },
}) => (
  <>
    <tbody>
      <tr>
        <td>
          <div className='transaction-image'>
            <img src={payment === "btc" ? cryptoicon : cashicon} alt='icon' />
          </div>
        </td>
        <td>{getDate(updatedAt)}</td>
        <td>{getTime(updatedAt)}</td>
        <td>
          <div
            className={`${status === "accept" ? "green" : "red"}-status-text`}>
            <span>{status === "accept" ? "Confirmed" : "Cancelled"}</span>
          </div>
        </td>
        <td>{payment === "btc" ? "BTC" : "USD"}</td>
        <td>{payment === "btc" ? cryptoPrice : "$" + amountInUsd}</td>
        <td>
          <div className='transaction-coins'>
            <img src={coinicon} alt='' /> {coin}
          </div>
        </td>
      </tr>
    </tbody>
  </>
);
const WithdrawRow = ({
  data: { payment, status, updatedAt, cryptoPrice, amountInUsd, coin },
}) => (
  <>
    <tbody>
      <tr>
        <td>
          <div className='transaction-image'>
            <img src={payment === "btc" ? cryptoicon : cashicon} alt='icon' />
          </div>
        </td>
        <td>{getDate(updatedAt)}</td>
        <td>{getTime(updatedAt)}</td>
        <td>
          <div
            className={`${status === "accept" ? "green" : "red"}-status-text`}>
            <span>{status === "accept" ? "Confirmed" : "Cancelled"}</span>
          </div>
        </td>
        <td>
          <div className='transaction-coins'>
            <img src={coinicon} alt='' /> {coin}
          </div>
        </td>
        <td>{payment === "btc" ? "BTC" : "USD"}</td>
        <td>{payment === "btc" ? cryptoPrice : "$" + amountInUsd}</td>
       
      </tr>
    </tbody>
  </>
);

const RedeemRechargeRow = ({ data: { status, updatedAt, coin,gameId } }) => (
  <>
    <tbody>
      <tr>
        <td>
          <div className='game-provider'>{gameId?.brandName?.name||''} </div>
        </td>
        <td>mike.johnsoncasinoplayer</td>
        <td>{getDate(updatedAt)}</td>
        <td>{getTime(updatedAt)}</td>
        <td>
          <div
            className={`${status === "accept" ? "green" : "red"}-status-text`}>
            <span>{status === "accept" ? "Confirmed" : "Cancelled"}</span>
          </div>
        </td>
        <td>
          <div className='transaction-coins'>
            <img src={coinicon} alt='' /> {coin}
          </div>
        </td>
      </tr>
    </tbody>
  </>
);
