import { useState, useRef, useEffect } from "react";
import { Form, Button, FormControl } from "react-bootstrap";
import "./search.css";
import buffalo from "../../assets/images/home/7.png";
import info from "../../assets/images/header/info-icon.svg";
import useDebounce from "../../hooks/useDebounce";
import { useCallback } from "react";
import { getGames } from "../../utils/Api";
import { Link,useNavigate } from "react-router-dom";
import { openGameLink } from "../../utils";

const SearchBoxComponent = () => {
  const navigate = useNavigate()

  const [search, setSearch] = useState(false);
  const [searchValue,setSearchValue] = useState('')
  const [gamesData,setSearchGamesData] = useState([])
  const handleChange = (e)=>{
    setSearchValue(e.target.value);
  }
  console.log('search---',search)
  const searchGames = useCallback(async(searchvalue)=>{
    if(search){
      console.log('here----------------')
    const res = await getGames(
      {limit:10,page:1,name:searchvalue,sort:'-createdAt'}
    );
    if (res.status === 200) {
      const { results } =
        res?.data?.game;
        setSearchGamesData(results || []);
    }
  }
},[search])

  useDebounce(searchValue, searchGames, 500);


  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  const handleSearch = () => {
    setSearch(!search);
  };
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setSearch(false);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  return (
    <div className="search-wrapper">
      <div className="search-box">
        <Form className=" search-box-form">
          <Button>
            <i className="las la-search" />
          </Button>
          <FormControl
            type="search"
            placeholder="Search"
            aria-label="Search"
            onClick={handleSearch}
            onChange={(e)=>handleChange(e)}
          />
        </Form>
      </div>
      {search && (
        <div className="search-result-section" ref={wrapperRef}>
          <div className="search-result-box">
            <div className="search-result-tilte">
              <h6>Search results</h6>
            </div>
            <div className="search-result-grid">
              { gamesData.length> 0 ? 
              gamesData.map((el)=>(
                <div className="search-result-tiles" key={el?._id?? el?.id}>
                <img src={el?.logo|| buffalo} alt="result-pic" />
                <div className="search-info">
                  <div className="search-result-name">
                    <h5>{el?.name||''}</h5>
                    <Link to={`/download?game=${el?._id?? el?.id}`}> <span>
                      <img src={info} alt="info-icon" />
                    </span></Link>
                  </div>
                  <p>By {el?.brandName?.name||''}</p>
                  <div className="search-result-btn">
                      <Button  onClick={() => openGameLink(el?.downloadUrl,el?.id??el?._id,navigate)}>Download</Button>
                  </div>
                </div>
              </div>
              ))
            : null}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchBoxComponent;
