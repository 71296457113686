import { useState, useContext, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import offer1 from "../../assets/images/offer/offer1.png";
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import CollectOffer from "./collect-offer";
import UserContext from "../../context/UserContext";
import { fetchOffers } from "../../utils/Api";
import { copyText } from "../../utils/index";

const OfferContent = () => {
  const [show, setShow] = useState(false);
  const [offersData, setOffersData] = useState([]);

  const handleCollectOffer = (string) => {
    if (string) {
      copyText(string);
    }
    setShow(!show);
  };

  const getOffers = async () => {
    const res = await fetchOffers({ limit: 20 });
    if (res.status === 200) {
      const { results } = res?.data?.data || {};
      setOffersData(results || []);
    }
  };

  useEffect(() => {
    getOffers();
  }, []);

  return (
    <div className="offers-section">
      <div className="container">
        <div className="normal-offers-section">
          <div className="section-title">
            <h6>Offers</h6>
          </div>
          <NormalOfferSlider
            handleCollectOffer={handleCollectOffer}
            offersData={offersData}
          />
        </div>
        {/* <div className="specail-offers-section">
          <div className="section-title">
            <h6>Special Deals</h6>
          </div>
          <SpecialOfferSlider handleCollectOffer={handleCollectOffer} />
        </div> */}
      </div>
      <Modal
        centered
        className="offer-popup"
        show={show}
        size="lg"
        onHide={() => handleCollectOffer()}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <CollectOffer handleCollectOffer={handleCollectOffer} />
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default OfferContent;

const NormalOfferSlider = ({ handleCollectOffer, offersData }) => {
  const { user } = useContext(UserContext);
  const settings = {
    dots: false,
    cssEase: "linear",
    dotsClass: "slick-dots slick-thumb",
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          autoplay: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          autoplay: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          autoplay: true,
        },
      },
    ],
  };
  return (
    <div className="normal-offer-slider">
      <Slider {...settings}>
        {offersData.length > 0 &&
          offersData.map((el) => (
            <div>
              <div className="normal-offer-tiles" key={el?.id ?? el?._id}>
                <div className="normal-offer-box purpule-box">
                  <img src={el?.offerImage || offer1} alt="" />
                  <div className="inner-box">
                    <h5>{el?.title || ""}</h5>
                    <h6>{el?.description || ""}</h6>
                    <Button onClick={() => handleCollectOffer(el?.title)}>
                      Collect now
                    </Button>
                  </div>
                  <div
                    className="bg-color"
                    style={{
                      background: `linear-gradient(0deg, ${el?.backgroundColor} 59.38%, rgba(255, 133, 23, 0) 100%)`,
                    }}
                  ></div>
                  <div className={` ${user ? "with-login" : "without-login"}`}>
                    <h5>{el?.title || ""}</h5>
                    <h6>{el?.description || ""}</h6>
                    <p>
                      To get this bonus please login to your account or Register
                      if you don’t have it yet
                    </p>
                    <div className="without-login-btn">
                      <Link to="/register" className="offer-sign">
                        Register
                      </Link>
                      <Link to="/login">Login</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </Slider>
    </div>
  );
};

// const SpecialOfferSlider = ({ handleCollectOffer }) => {
//   const settings = {
//     dots: false,
//     cssEase: "linear",
//     dotsClass: "slick-dots slick-thumb",
//     infinite: true,
//     speed: 500,
//     slidesToShow: 2,
//     slidesToScroll: 1,
//     responsive: [
//       {
//         breakpoint: 1025,
//         settings: {
//           autoplay: true,
//         },
//       },
//       {
//         breakpoint: 768,
//         settings: {
//           slidesToShow: 2,
//           autoplay: true,
//         },
//       },
//       {
//         breakpoint: 767,
//         settings: {
//           slidesToShow: 1,
//           autoplay: true,
//         },
//       },
//     ],
//   };
//   return (
//     <div className="special-offer-slider">
//       <Slider {...settings}>
//         <div className="special-offer-tiles">
//           <div className="special-offer-box ">
//             <h2>100% Sign up</h2>
//             <p>
//               Sign up NOW to get your bonus. Available for all games. For
//               MILKYWAY, HIGH ROLLER, PANDA MASTER, AND LOL(LIFE OF LUXURY) it is
//               a 100% sign-up bonus, and for other games, it is a 50% sign up
//               bonus. Chat now, and claim your sign-up bonus. Terms and
//               conditions applied.
//             </p>
//             <Button onClick={handleCollectOffer}>Collect now</Button>
//           </div>
//         </div>
//         <div className="special-offer-tiles">
//           <div className="special-offer-box ">
//             <h2>Weekend Bonus</h2>
//             <p>
//               Sign up NOW to get your bonus. Available for all games. For
//               MILKYWAY, HIGH ROLLER, PANDA MASTER, AND LOL(LIFE OF LUXURY) it is
//               a 100% sign-up bonus, and for other games, it is a 50% sign up
//               bonus. Chat now, and claim your sign-up bonus. Terms and
//               conditions applied.
//             </p>
//             <Button onClick={handleCollectOffer}>Collect now</Button>
//           </div>
//         </div>
//         <div className="special-offer-tiles">
//           <div className="special-offer-box ">
//             <h2>100% Sign up</h2>
//             <p>
//               Sign up NOW to get your bonus. Available for all games. For
//               MILKYWAY, HIGH ROLLER, PANDA MASTER, AND LOL(LIFE OF LUXURY) it is
//               a 100% sign-up bonus, and for other games, it is a 50% sign up
//               bonus. Chat now, and claim your sign-up bonus. Terms and
//               conditions applied.
//             </p>
//             <Button onClick={handleCollectOffer}>Collect now</Button>
//           </div>
//         </div>
//       </Slider>
//     </div>
//   );
// };
