import { useState,useEffect } from "react";
import { Tabs, Tab } from "react-bootstrap";
import {useLocation} from 'react-router-dom'
import tabicon1 from "../../assets/images/game/tab-icon1.svg";
import tabicon2 from "../../assets/images/game/tab-icon2.svg";
import tabicon3 from "../../assets/images/game/tab-icon3.svg";
import tabicon4 from "../../assets/images/game/tab-icon4.svg";
import tabicon5 from "../../assets/images/game/tab-icon5.svg";
import tabicon6 from "../../assets/images/game/tab-icon6.svg";
import tabicon7 from "../../assets/images/game/tab-icon7.svg";
import tabicon8 from "../../assets/images/game/tab-icon8.svg";
import { getGames,getBrandsByGame } from "../../utils/Api";
import AllGamesContent from "./all-games-tab";
import BrandGamesContent from "./brand-game-tab";

const GameTabs = () => {

  const location = useLocation();
  const { search } = location;
  const brandTab = new URLSearchParams(search).get("tabs");
  const initialPaginationValue = {
    limit: 12,
    page: 1,
    totalPages: 0,
    totalResults: 0,
    sort:  '-createdAt',
    gamesCat: brandTab ? brandTab  : 'all'
  };
  const [games,setGames] = useState([]);
  const [brands,setBrands ] = useState([])
  const [gamesView, setgamesView] = useState( brandTab ? 'brands'  : '');
  const [paginationValue, setPaginationValue] = useState(
    initialPaginationValue
  );
  

  // console.log('games---',games);
  useEffect(() => {
    const getGamesByType = async () => {
      if(gamesView !=='brands'){
        const res = await getGames(
          paginationValue
        );
        if (res.status === 200) {
          const { results, limit, page, totalPages, totalResults } =
            res?.data?.game;
            setGames(results || []);
          const pageData = { limit, page, totalPages, totalResults, sort:paginationValue.sort,gamesCat:paginationValue.gamesCat  };
          if (JSON.stringify(paginationValue) !== JSON.stringify(pageData)) {
            setPaginationValue(pageData);
          }
        }
      }else{
        const res = await getBrandsByGame(
          paginationValue
        );
        if (res.status === 200) {
          const { results,limit,page,totalPages,totalResults} =
            res?.data;
            setBrands(results || []);
          const pageData = { limit, page, totalPages, totalResults, sort:paginationValue.sort,gamesCat:paginationValue.gamesCat  };
          if (JSON.stringify(paginationValue) !== JSON.stringify(pageData)) {
            setPaginationValue(pageData);
          }
        }
      }
    };
    getGamesByType();
  }, [paginationValue, gamesView]);

  const handlegamesView = (tab) => {
    const copyPagination = {...paginationValue};
    copyPagination.page = 1;
    if(tab==='-playCount,-createdAt'){
      copyPagination.gamesCat = ''
      copyPagination.sort = tab
    }else if(!tab){
      copyPagination.gamesCat = ''
      copyPagination.sort = '-createdAt'
    }else{
      copyPagination.gamesCat = tab
      copyPagination.sort = '-createdAt'
    }
    setPaginationValue(copyPagination);
    setgamesView(tab);
  };
  const handlePagination = (pagevalue) => {
    const copypagination = { ...paginationValue };
    if (pagevalue <= copypagination.totalPages) {
      copypagination.page = pagevalue;
    }
    setPaginationValue(copypagination);
  };

  return (
    <div className="games-tab-section">
      <div className="container">
        <div className="games-tab-content">
          <div className="games-tab-grid">
            <Tabs defaultActiveKey={brandTab ==='brands'? "brands" :"all"} id="uncontrolled-tab-example" onSelect={(tab) => handlegamesView(tab)}>
              <Tab
                eventKey="all"
                title={
                  <h6>
                    <img src={tabicon1} alt="tab-icon" />
                    All Games
                  </h6>
                }
              >
                <AllGamesContent handlePagination={handlePagination} games={games} paginationValue={paginationValue}/>
              </Tab>
              <Tab
                eventKey="-playCount,-createdAt"
                title={
                  <h6>
                    <img src={tabicon2} alt="tab-icon" />
                    Popular
                  </h6>
                }
              >
                <AllGamesContent handlePagination={handlePagination} games={games} paginationValue={paginationValue}/>
              </Tab>
              <Tab
                eventKey="-createdAt"
                title={
                  <h6>
                    <img src={tabicon3} alt="tab-icon" />
                    New
                  </h6>
                }
              >
                <AllGamesContent handlePagination={handlePagination} games={games} paginationValue={paginationValue}/>
              </Tab>
              <Tab
                eventKey="brands"
                title={
                  <h6>
                    <img src={tabicon4} alt="tab-icon" />
                    Brands
                  </h6>
                }
              >
                <BrandGamesContent handlePagination={handlePagination} brands={brands} paginationValue={paginationValue}/>
              </Tab>
              <Tab
                eventKey="keno"
                title={
                  <h6>
                    <img src={tabicon5} alt="tab-icon" />
                    Keno
                  </h6>
                }
              >
                <AllGamesContent handlePagination={handlePagination} games={games} paginationValue={paginationValue}/>
              </Tab>
              <Tab
                eventKey="fish"
                title={
                  <h6>
                    <img src={tabicon6} alt="tab-icon" />
                    Fish
                  </h6>
                }
              >
                <AllGamesContent handlePagination={handlePagination} games={games} paginationValue={paginationValue}/>
              </Tab>
              <Tab
                eventKey="slots"
                title={
                  <h6>
                    <img src={tabicon7} alt="tab-icon" />
                    Slots
                  </h6>
                }
              >
                <AllGamesContent handlePagination={handlePagination} games={games} paginationValue={paginationValue}/>
              </Tab>
              <Tab
                eventKey="others"
                title={
                  <h6>
                    <img src={tabicon8} alt="tab-icon" />
                    Others
                  </h6>
                }
              >
                <AllGamesContent handlePagination={handlePagination} games={games} paginationValue={paginationValue}/>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
};
export default GameTabs;
