import "./nodata.css";

const NoData = ({heading}) => {
  return (
    <div className="no-data">
      <h6>{heading || 'No Data'}</h6>
    </div>
  );
};
export default NoData;
