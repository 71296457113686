import { Button } from "react-bootstrap";
import thumbiocn from "../../assets/images/offer/thumb-icon.svg";

const CollectOffer = ({ handleCollectOffer }) => {
  const handleOpenChat = () => {
    document.getElementById("liveChat").click();
    handleCollectOffer();
  };
  return (
    <div className="collect-offer-content">
      <img src={thumbiocn} alt="" />
      <h6>Offer Successfully Copied </h6>
      <p>Please paste it in live chat to get rewarded</p>
      <Button onClick={handleOpenChat}>Open Chat</Button>
    </div>
  );
};

export default CollectOffer;
