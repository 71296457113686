import { useState, useEffect, useContext } from "react";
import { socket } from "../../../config/socket";
import UserContext from "../../../context/UserContext";
import { Link } from "react-router-dom";
import { Dropdown, Offcanvas, Navbar, Nav } from "react-bootstrap";
import usericon from "../../../assets/images/header/user1.png";
import coinicon from "../../../assets/images/header/coin-icon.svg";
import logo from "../../../assets/images/header/logo3d.png";
import "./client-header.css";
import WalletMenu from "../menu/walletmenu";
import ClientMenu from "../menu/menu";
import Notification from "../../Notification";
import { changeCurrencyFormat } from "../../../utils";
import NotificationIcon from "../../header/NotificationIcon";

const ClientHeader = () => {
  const { user, notifications, setNotifications } = useContext(UserContext);
  const [showNotification, setShowNotification] = useState(false);
  const [showWallet, setShowWallet] = useState(false);
  const [showBellNotify, setBellNotify] = useState(false);
  const handleNotification = () => {
    setShowNotification(!showNotification);
  };
  const handleWallet = () => {
    setShowWallet(!showWallet);
  };

  useEffect(() => {
    socket.on("newNotificationClient", (data) => {
      try {
        const notificationsCopy = [...notifications];
        notificationsCopy.unshift(data.notification);
        setNotifications(notificationsCopy);
      } catch (error) {
        console.log(error);
      }
    });
  }, [notifications, setNotifications]);

  useEffect(() => {
    const findIsReadExist = notifications.findIndex((el) => !el.isRead);
    if (findIsReadExist > -1) {
      setBellNotify(true);
    }
  }, [notifications]);

  return (
    <>
      <div className='admin-header'>
        <Link to='/client-profile' className='brand-name'>
          <img src={logo} alt='logo' /> <span>King of Fish Games</span>
        </Link>
        <div className='user-loggedin'>
          <NotificationIcon
            handleNotification={handleNotification}
            showBellNotify={showBellNotify}
          />
          <span className='wallet-section' onClick={handleWallet}>
            <img src={coinicon} alt='icon' />{" "}
            {changeCurrencyFormat(user?.coin || 0)}
          </span>
          <div className='user-pic'>
            <Dropdown>
              <Dropdown.Toggle id='dropdown-basic'>
                <img src={user?.avatar || usericon} alt='user' />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item eventKey='logout'>Logout</Dropdown.Item>
                <Dropdown.Item href='#/action-2'>Visit profile</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>

        <div className='header-menu'>
          <Navbar expand='lg'>
            <Navbar.Toggle aria-controls='basic-navbar-nav' />
            <Navbar.Collapse id='responsive-navbar-nav'>
              <Nav>
                <ClientMenu />
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </div>
      </div>
      {showNotification && (
        <Notification
          showNotification={showNotification}
          handleNotification={handleNotification}
        />
      )}

      {window.innerWidth <= "1024" ? (
        <Offcanvas
          show={showWallet}
          onHide={handleWallet}
          placement='end'
          className='wallet-wrapper'
          backdrop={true}
          keyboard={true}>
          <WalletMenu />
        </Offcanvas>
      ) : (
        ""
      )}
    </>
  );
};

export default ClientHeader;
