import { Link } from "react-router-dom";
import "./home.css";

const OfferSection = () => {
  return (
    <div className="offer-section">
      <div className="container">
        <h2>
          Enjoy the offers
          <span className="offer-heading"> Exclusively </span> For You!
        </h2>
        <p>Get latest offers and coupons and start playing now!</p>
        <Link to="/offers">Get new offer</Link>
      </div>
    </div>
  );
};

export default OfferSection;
