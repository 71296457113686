import { useCallback, useState, useEffect } from "react";
import SearchBoxComponent from "../search/searchBoxComponent";
import { Tabs, Tab, Button, Modal, Form } from "react-bootstrap";
import "./my-game.css";
import game from "../../../assets/images/wallet/game.svg";
import plus from "../../../assets/images/game/plus-red-icon.svg";
import remote from "../../../assets/images/wallet/gameremote.svg";
import thumbiocn from "../../../assets/images/offer/thumb-icon.svg";
import ClientLayout from "../clientlayout/clientlayout";
import {
  createGameRequest,
  fetchGameRequest,
  getGames,
} from "../../../utils/Api";
import { getDate } from "../../../utils";
import PagePagination from "../../pagination/Pagination";
import NoData from "../../noData/index";
import GameInfoPopup from "./gameInfoPopup.jsx";
import TableLoader from "../../table_loader/tableloader.jsx";

const MyGames = () => {
  const [gameInfo, setGameInfo] = useState(false);
  const [gameRequest, setGameRequest] = useState(false);
  const [gameProcess, setGameProcess] = useState(false);
  const [searchGamesdata, setSearchGamesData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedGame, setSelectedGame] = useState(null);
  const handleGameInfo = () => {
    setGameInfo(!gameInfo);
  };
  const handleGameRequest = () => {
    setGameRequest(!gameRequest);
  };
  const handleGameProcess = () => {
    setGameRequest(false);
    setGameProcess(!gameProcess);
  };
  const searchGames = useCallback(async (searchvalue) => {
    const res = await getGames({
      limit: 100,
      page: 1,
      name: searchvalue,
      sort: "-createdAt",
    });
    if (res.status === 200) {
      const { results } = res?.data?.game;
      setSearchGamesData(results || []);
    }
  }, []);

  // requestFetch
  const [requestgameList, setRequestgameList] = useState([]);
  const [requestGameView, setRequestGameView] = useState("accept");
  const initialPaginationValue = {
    limit: 0,
    page: 1,
    totalPages: 0,
    totalResults: 0,
    status: requestGameView,
  };
  const [paginationValue, setPaginationValue] = useState(
    initialPaginationValue
  );

  useEffect(() => {
    const getGameRequestData = async () => {
      setLoading(true);
      const res = await fetchGameRequest(paginationValue);
      setLoading(false);
      if (res.status === 200) {
        const { results, limit, page, totalPages, totalResults } =
          res?.data?.gameRequest;
        setRequestgameList(results || []);
        const pageData = {
          limit,
          page,
          totalPages,
          totalResults,
          status: requestGameView,
        };
        if (JSON.stringify(paginationValue) !== JSON.stringify(pageData)) {
          setPaginationValue(pageData);
        }
      }
    };
    getGameRequestData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationValue?.page, requestGameView]);

  const handleGameRequestView = (tab) => {
    setPaginationValue({ ...paginationValue, status: tab, page: 1 });
    setRequestGameView(tab);
  };
  const handlePagination = (pagevalue) => {
    const copypagination = { ...paginationValue };
    if (pagevalue <= copypagination.totalPages) {
      copypagination.page = pagevalue;
    }
    setPaginationValue(copypagination);
  };
  const handleSelectGame = (data) => {
    setSelectedGame(data);
    handleGameInfo();
  };
  return (
    <ClientLayout>
      <div className='my-game-page'>
        <div className='container'>
          <div className='admin-content-wrapper'>
            <div className='admin-section-title'>
              <h3>My games</h3>
              <Button onClick={handleGameRequest}>
                <img src={plus} alt='icon' /> New game request
              </Button>
            </div>

            <div className='admin-tab-section'>
              <Tabs
                defaultActiveKey='accept'
                id='uncontrolled-tab-example'
                onSelect={(tab) => handleGameRequestView(tab)}>
                <Tab eventKey='accept' title='Games approved'>
                  <div className='approved-game'>
                    <SearchBoxComponent />
                    {!loading ? (
                      <div className='approved-game-content'>
                        <div className='wallet-user-recent-game-grid'>
                          {requestgameList.length > 0 ? (
                            <>
                              {requestgameList.map((el) => (
                                <div
                                  className='recent-game_box'
                                  key={el?.id ?? el?._id}>
                                  <img src={el?.gameId?.logo || game} alt='' />
                                  <div className='recent-game-detail'>
                                    <div className='recent-game-detail-bottom'>
                                      <div className='recent-game-detail-left'>
                                        <p>{el?.gameId?.name}</p>
                                        <div className='recent-game-detail-img'>
                                          <img src={remote} alt='' />
                                          <span>
                                            {el?.gameId?.playCount || 0} Games
                                          </span>
                                        </div>
                                      </div>
                                      <div className='recent-game-detail-right'>
                                        <Button
                                          className='red-btn'
                                          onClick={() =>
                                            handleSelectGame({
                                              gameUsername: el?.gameUsername,
                                              gamePassword: el?.gamePassword,
                                              ...el?.gameId,
                                            })
                                          }>
                                          Play
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </>
                          ) : (
                            <NoData heading='No Game Data' />
                          )}
                        </div>

                        {requestgameList.length > 1 && (
                          <div className='client-pagination-sec'>
                            <PagePagination
                              page={paginationValue?.page}
                              totalpages={paginationValue?.totalPages}
                              handlePagination={handlePagination}
                            />
                          </div>
                        )}
                      </div>
                    ) : (
                      <TableLoader />
                    )}
                  </div>
                </Tab>
                <Tab eventKey='idle' title='Waiting for approval'>
                  <div className='waiting-game'>
                    <SearchBoxComponent />
                    {!loading ? (
                      <>
                        <div className='waiting-game-content'>
                          <div className='wallet-user-recent-game-grid'>
                            {requestgameList.length > 0 ? (
                              <>
                                {requestgameList.map((el) => (
                                  <div
                                    className='recent-game_box'
                                    key={el?.id ?? el?._id}>
                                    <img
                                      src={el?.gameId?.logo || game}
                                      alt=''
                                    />
                                    <div className='recent-game-detail'>
                                      <div className='recent-game-detail-bottom'>
                                        <div className='recent-game-detail-left'>
                                          <p>{el?.gameId?.name}</p>
                                          <div className='recent-game-detail-img'>
                                            <img src={remote} alt='' />
                                            <span>
                                              {el?.gameId?.playCount || 0} Games
                                            </span>
                                          </div>
                                        </div>
                                        <div className='recent-game-detail-right'>
                                          <p>{getDate(el?.createdAt)}</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </>
                            ) : (
                              <NoData heading='No Game Data' />
                            )}
                          </div>
                          {requestgameList.length > 1 && (
                            <div className='client-pagination-sec'>
                              <PagePagination
                                page={paginationValue?.page}
                                totalpages={paginationValue?.totalPages}
                                handlePagination={handlePagination}
                              />
                            </div>
                          )}
                        </div>
                      </>
                    ) : (
                      <TableLoader />
                    )}
                  </div>
                </Tab>
              </Tabs>
              {gameInfo && (
                <GameInfoPopup
                  handleGameInfo={handleGameInfo}
                  gameInfo={gameInfo}
                  selectedGame={selectedGame}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <GameRequestPopup
        handleGameRequest={handleGameRequest}
        gameRequest={gameRequest}
        handleGameProcess={handleGameProcess}
        searchGames={searchGames}
        searchGamesdata={searchGamesdata}
        setSearchGamesData={setSearchGamesData}
      />
      <GameRequestProcess
        gameProcess={gameProcess}
        handleGameProcess={handleGameProcess}
      />
    </ClientLayout>
  );
};

export default MyGames;

const GameRequestPopup = ({
  gameRequest,
  handleGameRequest,
  handleGameProcess,
  searchGames,
  searchGamesdata,
  setSearchGamesData,
}) => {
  const handleCheckBox = (id, checkValue) => {
    const copySearchGamesdata = JSON.parse(JSON.stringify(searchGamesdata));
    const checkIndex = copySearchGamesdata.findIndex((el) => el.id === id);
    if (checkIndex !== -1) {
      copySearchGamesdata[checkIndex].isChecked = checkValue;
    }
    setSearchGamesData(copySearchGamesdata);
  };
  const sendGameRequest = async () => {
    let filterRequestGame = [];
    searchGamesdata.forEach((el) => {
      if (el.isChecked) filterRequestGame.push(el.id);
    });
    if (filterRequestGame.length > 0) {
      const res = await createGameRequest(filterRequestGame);
      if (res.status === 201) {
        handleGameProcess();
      }
    }
  };

  const closePopup = () => {
    const copySearchGamesdata = JSON.parse(JSON.stringify(searchGamesdata));
    copySearchGamesdata.forEach((el) => (el.isChecked = false));
    setSearchGamesData(copySearchGamesdata);
    handleGameRequest();
  };

  return (
    <Modal
      size='xl'
      className='game-request-popup'
      show={gameRequest}
      onHide={() => handleGameRequest()}
      centered
      fullscreen>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className='game-request-wrapper'>
          <div className='game-request-heading'>
            <h2>New game request</h2>
            <p>Please choose the games you want to play</p>
          </div>
          <div className='game-request-search'>
            <SearchBoxComponent searchFunction={searchGames} />
          </div>
          <div className='game-request-list'>
            <div className='wallet-user-recent-game-grid'>
              {!!searchGamesdata &&
                searchGamesdata.map((el) => (
                  <div className='recent-game_box'>
                    <div
                      className='select-game-request'
                      onClick={() => handleCheckBox(el.id, !el.isChecked)}>
                      <Form.Check
                        type='checkbox'
                        checked={el.isChecked ? true : false}
                      />
                    </div>
                    <img src={el?.logo || game} alt='' />
                    <div className='recent-game-detail'>
                      <div className='recent-game-detail-bottom'>
                        <div className='recent-game-detail-left'>
                          <p>{el.name || ""}</p>
                          <div className='recent-game-detail-img'>
                            <img src={remote} alt='' />
                            <span>{el?.playCount || 0} Games</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div className='popup-footer-btn'>
          <Button className='popup-black' onClick={() => closePopup()}>
            Cancel
          </Button>
          <Button className='popup-red' onClick={sendGameRequest}>
            Send Games Request
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const GameRequestProcess = ({ gameProcess, handleGameProcess }) => {
  return (
    <Modal
      centered
      className='process-popup'
      show={gameProcess}
      size='lg'
      onHide={() => handleGameProcess()}>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className='collect-offer-content'>
          <img src={thumbiocn} alt='' />
          <h6>Your game request is processed </h6>
          <p>We will notify you when it is approved</p>
          <Button onClick={handleGameProcess}>Back to Account</Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
