import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  // useParams,
  // useLocation,
} from "react-router-dom";
import { Toaster } from "react-hot-toast";
import Home from "./components/home";
import UserContext from "./context/UserContext";
import { getImageSetting, getNotifications, getUser } from "./utils/Api";
import logo from "./assets/images/header/logo3d.png";
import "react-phone-input-2/lib/bootstrap.css";
import "./assets/css/line-awesome.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Register from "./components/register/register";
import Login from "./components/login/login";
import Forget from "./components/forget/forget";
import ResetPassword from "./components/forget/reset-password";
import PrivacyPolicy from "./components/privacy-pages/privacy-policy";
import Terms from "./components/privacy-pages/terms";
import Games from "./components/games";
import BrandDetail from "./components/games/brand-detail";
import GameDownload from "./components/games/game-download";
import Offer from "./components/offer";
import ContactUs from "./components/footer/contact-us";
import AboutUs from "./components/about";
import VerifyEmail from "./components/login/verifyEmail";
import PageNotFound from "./components/404_page";
// import { socket } from "./config/socket";
import Wallet from "./components/clients/wallet/wallet";
import Transaction from "./components/clients/transaction/transaction";
import MyGames from "./components/clients/my-game";
import GameRecharge from "./components/clients/game-recharge";
import GameRedeem from "./components/clients/game-redeem";
import Setting from "./components/clients/settingpage/setting";
import "./responsive.css";
import UserRoute from "./components/userRoutes/userRoutes";
// import KYCForm from "./components/kyc/kycForm";
import { authInstance, gameInstance } from "./config/axios";
// import KycInfo from "./components/kycInfo/kycInfo";

export const queryString = () => {
  let query = window.location.search.substr(1);
  let resultval = {};
  query.split("&").forEach((part) => {
    let item = part.split("=");
    resultval[item[0]] = decodeURIComponent(item[1]);
  });
  return resultval;
};

function App() {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [adminDetail, setAdminDetail] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [isIpBan, setIpBan] = useState(false);
  const [tokens, setTokens] = useState(
    localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token"))
      : null
  );
  const [resetPassEmailValue, setResetPassEmailValue] = useState("");
  const [landingPagesImg, setLandingPagesImg] = useState(null);
  const [openKYCModal, setOpenKYCModal] = useState(false);
  // const openCloseKYCForm = useCallback(() => {
  //   setOpenKYCModal(!openKYCModal);
  // }, [openKYCModal]);
  const [games, setGames] = useState([]);
  const [brands, setBrands] = useState([]);
  const [callPopuplarGamesApi, setCallPopularGamesApi] = useState(false);
  const [kycStatus, setKycStatus] = useState("");
  const [isKyc, setIsKyc] = useState(false);
  const { open } = queryString();
  console.log("open", open);

  const getUserCallback = (data) => {
    console.log("data", data);
    setIsLoading(false);
    if (data.success) {
      setUser(data.user);
    }
    if (data?.KycStatus) {
      setKycStatus(data?.KycStatus);
    }
  };

  console.log("kycStatus***", kycStatus);

  useEffect(() => {
    const fetchNotification = async () => {
      const res = await getNotifications();
      if (res.status === 200) {
        let notificationsArr = res?.data?.data || [];
        const filterNotifications = notificationsArr.filter(
          (el) => el.contentId !== null
        );
        setNotifications(filterNotifications);
      }
    };
    if (user) {
      fetchNotification();
    }
  }, [user]);

  useEffect(() => {
    const getHomeData = async () => {
      const res = await gameInstance().get("/home-page-data");
      if (res.status === 200) {
        setGames(res.data.gameSliderData.results);
        setBrands(res.data.brands.results);
      }
    };
    (async () => {
      try {
        const res = await getImageSetting();
        if (res.status === 200) {
          setLandingPagesImg(res.data.result);
        }
        await authInstance().get("/check-ip");

        setCallPopularGamesApi(true);
      } catch (error) {
        setIpBan(true);
      }
      getHomeData();
      if (tokens) {
        setIsLoading(true);
        getUser(getUserCallback);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isIpBan ? (
        <div style={{ height: "100vh", background: "#fff" }}>
          {" "}
          <h1>404 Not Found</h1>{" "}
        </div>
      ) : (
        <UserContext.Provider
          value={{
            user,
            setUser,
            tokens,
            setTokens,
            resetPassEmailValue,
            setResetPassEmailValue,
            setAdminDetail,
            adminDetail,
            notifications,
            setNotifications,
            landingPagesImg,
            kycStatus,
            setKycStatus,
            isKyc,
            setIsKyc,
          }}
        >
          {isLoading ? (
            <Loader />
          ) : (
            <div className="App">
              <Toaster />
              <Router>
                <Routes>
                  <Route
                    exact
                    path="/"
                    element={
                      <Home
                        games={games}
                        brands={brands}
                        callPopuplarGamesApi={callPopuplarGamesApi}
                      />
                    }
                  />
                  <Route exact path="/register" element={<Register />} />
                  <Route
                    exact
                    path="/login"
                    element={<Login setOpenKYCModal={setOpenKYCModal} />}
                  />
                  <Route exact path="/forget-password" element={<Forget />} />
                  <Route exact path="/verify-email" element={<VerifyEmail />} />
                  <Route
                    exact
                    path="/reset-password"
                    element={<ResetPassword />}
                  />
                  <Route
                    exact
                    path="/privacy-policy"
                    element={<PrivacyPolicy />}
                  />
                  <Route exact path="/terms" element={<Terms />} />
                  <Route exact path="/about" element={<AboutUs />} />
                  <Route exact path="/contact" element={<ContactUs />} />
                  <Route exact path="/offers" element={<Offer />} />
                  <Route exact path="/games" element={<Games />} />
                  <Route exact path="/download" element={<GameDownload />} />
                  <Route exact path="/brand-detail" element={<BrandDetail />} />
                  {/* <Route exact path='/kycInfo' element={<KycInfo />} /> */}
                  <Route path="*" element={<PageNotFound />} />
                  <Route
                    exact
                    path="/"
                    element={
                      <UserRoute
                        setOpenKYCModal={setOpenKYCModal}
                        openKYCModal={openKYCModal}
                      />
                    }
                  >
                    <Route exact path="/client-profile" element={<Wallet />} />
                    <Route exact path="/my-games" element={<MyGames />} />
                    <Route
                      exact
                      path="/game-recharge"
                      element={<GameRecharge />}
                    />
                    <Route exact path="/game-redeem" element={<GameRedeem />} />
                    <Route
                      exact
                      path="/transaction"
                      element={<Transaction />}
                    />
                    <Route exact path="/setting" element={<Setting />} />
                  </Route>
                </Routes>
                {/* {openKYCModal && (
                  <KYCForm
                    openCloseKYCForm={openCloseKYCForm}
                    openKYCModal={openKYCModal}
                  />
                )}
                {open === "kycInfo" ? (
                  <KycInfo
                    openKycInfo={open}
                    openCloseKYCInfo={openCloseKYCInfo}
                  />
                ) : null} */}
              </Router>
            </div>
          )}
        </UserContext.Provider>
      )}
    </>
  );
}

export default App;

const Loader = () => {
  return (
    <div className="loader">
      <div className="crash-logo">
        <div className="animated-logo">
          <img src={logo} alt="" />
        </div>
      </div>
    </div>
  );
};
