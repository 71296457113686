import banner from "../../../assets/images/game/popup-banner.png";
import { capitalizeFirstLetter,  openGameLink } from "../../../utils";
import {  Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const GameInfoPopup = ({ gameInfo, handleGameInfo,selectedGame }) => {

    const navigate = useNavigate();
    const gameDownloadClick =(url,gameId)=>{
     openGameLink(url,gameId,navigate)
    }
    return (
      <Modal
        size="lg"
        className="game-info-popup"
        show={gameInfo}
        onHide={() => handleGameInfo()}
        centered
      >
        <Modal.Header closeButton>
          <div className="game-info-banner">
            <img src={selectedGame?.logo||banner} alt="banner" />
            <div className="game-info-name">
              <h2>{selectedGame?.name||''}</h2>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="game-info-wrapper">
            <div className="game-info-content">
              <h6>Game info</h6>
              <div className="game-info-grid">
                <div className="game-info-left">
                  <p>Games type</p>
                </div>
                <div className="game-info-right">
                  <p>{selectedGame?.gameCategory}</p>
                </div>
              </div>
              <div className="game-info-grid">
                <div className="game-info-left">
                  <p>Devices</p>
                </div>
                <div className="game-info-right">
                  <p>{selectedGame?.device.map((el,i)=>`${capitalizeFirstLetter(el)}${selectedGame?.device?.length !== i+1 ? ', ':''}`) || ''}</p>
                </div>
              </div>
            </div>
            {selectedGame?.id && <div className="account-info-content">
              <h6>Account info</h6>
              <div className="game-info-grid">
                <div className="game-info-left">
                  <p>User name</p>
                </div>
                <div className="game-info-right">
                  <p>{selectedGame?.gameUsername || ''}</p>
                </div>
              </div>
              <div className="game-info-grid">
                <div className="game-info-left">
                  <p>Password</p>
                </div>
                <div className="game-info-right">
                  <p>{selectedGame?.gamePassword || ''}</p>
                </div>
              </div>
            </div>}
            
            <div className="popup-footer-btn">
              <Button className="popup-black" onClick={handleGameInfo}>
                Cancel
              </Button>
              <Button className="popup-red" onClick={()=>gameDownloadClick(selectedGame?.downloadUrl,selectedGame?._id ?? selectedGame?.id)}>
                Download Game
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  export default GameInfoPopup