import Layout from "../layout/layout";
import React, { useState } from "react";
import { Form, Button, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Link, useNavigate,useLocation  } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { RegisterSchema } from "../../validationSchema/authSchema";
import { signup } from "../../utils/Api";
import PhoneInput from "react-phone-input-2";
import toast from "react-hot-toast";
import "./register.css";

const Register = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { search } = location;
  const referralCode = new URLSearchParams(search).get("referral");
  const [pass, setPass] = useState("password");
  const [cpass, setCPass] = useState("password");
  const [isLoading, setIsLoading] = useState(false);
  const [checkEmailInput,setCheckEmailInput] = useState('')
  
  const handleCPass = () => {
    setCPass(!cpass);
  };
  const handlePass = () => {
    setPass(!pass);
  };
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
    setError,
  } = useForm({ resolver: yupResolver(RegisterSchema) });
  const handleSignup = async (values) => {
    setIsLoading(true);
    const res = await signup(values);
    setIsLoading(false);
    if (res.message === "Nick Name already taken") {
      setError(
        "nickName",
        { type: "custom", message:res.message },
        { shouldFocus: true }
      );
    }
    else if (res.message === "Email already taken") {
      setError(
        "email",
        { type: "custom", message: "Email already taken" },
        { shouldFocus: true }
      );
    }else if(res.message==='Referral invalid'){
      setError(
        "referralCode",
        { type: "custom", message: "Referral code is invalid" },
        { shouldFocus: true }
      );
    } else if (res.msg === "Email send for verification") {
      toast.success(
        "Registered Successfully. An email sent to your mail, please verified",
        {
          duration: 3000,
        }
      );
      navigate("/");
    }
  };

  //   const showPassword = (name) => {
  //     if (!showPasswordEye.includes(name)) {
  //       setShowPasswordEye((old) => [...old, name]);
  //     } else {
  //       setShowPasswordEye((old) => old.filter((el) => el !== name));
  //     }
  //   };

  const handleChange = (e) => {
    setValue("phoneNumber", e);
    setError("phoneNumber", "customError");
  };

  const handleAgreeChange = (e) => {
    setValue("agree", e);
    setError("agree", "customError");
  };


  return (
    <Layout>
      <div className="signup">
        <div className="auth-page">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="register-form">
                  <h1>Register</h1>
                  <p className="auth-para">
                  Please create your account to join the best online social gaming experience
                  </p>
                  <div className="rigister-tab">
                    <Form onSubmit={handleSubmit(handleSignup)}>
                      {/* <Form.Group className="form-group">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          type="text"
                          name="name"
                          className={errors.name ? "error-field" : ""}
                          {...register("name")}
                          autoComplete="off"
                        />
                        {errors?.name ? (
                          <p className="error-text">{errors.name.message}</p>
                        ) : (
                          ""
                        )}
                      </Form.Group> */}
                      <Form.Group className="form-group">
                        <Form.Label>Nickname</Form.Label>
                        <Form.Control
                          type="text"
                          name="nickName"
                          className={errors.nickName ? "error-field" : ""}
                          {...register("nickName")}
                          autoComplete="off"
                        />
                        {errors?.nickName ? (
                          <p className="error-text">
                            {errors.nickName.message}
                          </p>
                        ) : (
                          ""
                        )}
                      </Form.Group>
                      <Form.Group className="form-group">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type="text"
                          name="email"
                          className={errors.email ? "error-field" : ""}
                          {...register("email")}
                          onChange={(e)=>setCheckEmailInput(e.target.value)}
                          autoComplete="off"
                        />
                        {checkEmailInput && checkEmailInput.length>0 && <><span className="input-icon">
                          <i class="las la-check"></i>
                        </span></>}
                        
                        {errors?.email ? (
                          <p className="error-text">{errors.email.message}</p>
                        ) : (
                          ""
                        )}
                      </Form.Group>
                      <Form.Group className="form-group">
                        <Form.Label>Phone number</Form.Label>
                        <PhoneInput
                          country={"us"}
                          onChange={handleChange}
                          inputProps={{
                            name: "phoneNumber",
                          }}
                          className={errors.phoneNumber ? "error-field" : ""}
                        />
                        {errors?.phoneNumber ? (
                          <p className="error-text">
                            {errors.phoneNumber.message}
                          </p>
                        ) : (
                          ""
                        )}
                      </Form.Group>

                      <Form.Group className="form-group">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                          type={`${pass ? `password` : `text`}`}
                          className={errors.password ? "error-field" : ""}
                          name="password"
                          {...register("password")}
                          autoComplete="off"
                        />
                        <span className="input-icon" onClick={handlePass}>
                          <i
                            class={pass ? "las la-eye" : "las la-eye-slash"}
                          ></i>
                        </span>
                        {errors?.password ? (
                          <p className="error-text">
                            {errors.password.message}
                          </p>
                        ) : (
                          ""
                        )}
                      </Form.Group>
                      <Form.Group className="form-group">
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control
                          type={`${cpass ? `password` : `text`}`}
                          name="verifyPassword"
                          className={errors.verifyPassword ? "error-field" : ""}
                          {...register("verifyPassword")}
                          autoComplete="off"
                        />
                        <span className="input-icon" onClick={handleCPass}>
                          <i
                            class={cpass ? "las la-eye" : "las la-eye-slash"}
                          ></i>
                        </span>
                        {errors?.verifyPassword ? (
                          <p className="error-text">
                            {errors.verifyPassword.message}
                          </p>
                        ) : (
                          ""
                        )}
                      </Form.Group>

                      <Form.Group className="form-group">
                        <Form.Label>
                          Referal code <span>(Optional)</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="referralCode"
                          className={errors.referralCode ? "error-field" : ""}
                          {...register("referralCode")}
                          autoComplete="off"
                          defaultValue={referralCode ||''}
                        />
                        {errors?.referralCode ? (
                          <p className="error-text">
                            {errors.referralCode.message}
                          </p>
                        ) : (
                          ""
                        )}
                      </Form.Group>
                      <Form.Group className="form-group check-field">
                        <Form.Check
                          name="agree"
                          type="checkbox"
                          onChange={handleAgreeChange}
                          {...register("agree")}
                        />
                        <Form.Label>
                          I agree to all{" "}
                          <Link to="/terms"> Terms & Conditions </Link>
                          and <Link to="/privacy-policy"> Privacy policy </Link>
                        </Form.Label>
                        {errors?.agree ? (
                          <p className="error-text">{errors.agree.message}</p>
                        ) : (
                          ""
                        )}
                      </Form.Group>

                      <Form.Group className="form-group check-field">
                        <Form.Check type="checkbox" />
                        <Form.Label>Subscribe to our newsletter</Form.Label>
                      </Form.Group>

                      <div className="text-center">
                        <Button className="s-btn" type="submit">
                          {isLoading ? (
                            <Spinner animation="border" />
                          ) : (
                            "Register"
                          )}
                        </Button>
                      </div>
                    </Form>
                    <p className="already">
                      Arready registered?
                      <Link to="/login" className="forget-link">
                        Login
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>

    
  );
};

export default Register;
