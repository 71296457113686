import { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/header/logo3d.png";
import cash from "../../assets/images/footer/cash-app.png";
import visa from "../../assets/images/footer/visa.png";
import apple from "../../assets/images/footer/apple-pay.png";
import master from "../../assets/images/footer/master.png";
import maestro from "../../assets/images/footer/maestro.png";
import american from "../../assets/images/footer/american.png";
import gpay from "../../assets/images/footer/g-pay.png";
import bitcoin from "../../assets/images/footer/bitcoin.png";
import facebook from "../../assets/images/footer/facebook.svg";
import youtube from "../../assets/images/footer/youtube.svg";
import telegram from "../../assets/images/footer/telegram.svg";
import mail from "../../assets/images/footer/mail.svg";
import "./footer.css";
import SupportChat from "../supportchat/chat";
import chaticon from "../../assets/images/chat/chat-icon.svg";
import toast from "react-hot-toast";

const Footer = ({ prop }) => {
  const [show, setShow] = useState(false);
  // const navigate = useNavigate();
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShow(false);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const handleClick = (e) => {
    const tokenData = localStorage.getItem("token");
    let checkTokenData = false;
    if (tokenData) {
      const tokenParsed = JSON.parse(tokenData) || {};
      const { token, expires } = tokenParsed?.access;
      if (token && new Date() < new Date(expires)) {
        checkTokenData = true;
      }
    }
    if (checkTokenData) {
      setShow(e);
    } else {
      // navigate("/login");
      toast.error("You must login into your account first in order to use customer support chat.");
    }
  };
  return (
    <div className='footer'>
      <div className='container'>
        <div className='top-footer'>
          <img src={cash} alt='' />
          <img src={visa} alt='' />
          <img src={apple} alt='' />
          <img src={master} alt='' />
          <img src={maestro} alt='' />
          <img src={american} alt='' />
          <img src={gpay} alt='' />
          <img src={bitcoin} alt='' />
        </div>
        <div className='main-footer'>
          <div className='logo'>
            <Link to='/'>
              <img src={logo} alt='' />
            </Link>
          </div>
          <div className='main-footer-grid'>
            <div className='footer-info'>
              <h6>
                The King of Fish Games was created with you in mind. If you’re a
                gamer and love the excitement found in fish table games, you
                won’t be able to get enough of our completely mobile app!
              </h6>
              <div className='mail-box'>
                {/* <Form className="mail-box-form">
                  <FormControl
                    type="mail"
                    placeholder="Subscribe to get bonuses"
                  />
                  <Button type="submit">Send</Button>
                </Form> */}
              </div>
            </div>
            <div className='footer-links'>
              <h6>Quick Links</h6>
              <ul>
                <li>
                  <Link to='/'>Home</Link>
                </li>
                <li>
                  <Link to='/games'>Games</Link>
                </li>
                <li>
                  <Link to='/offers'>Offers</Link>
                </li>
                <li>
                  <Link to='/about'>About</Link>
                </li>
                {/* <li>
                  <Link to="/contact">Contact Us</Link>
                </li> */}
              </ul>
            </div>
            <div className='footer-links'>
              <h6>Policy</h6>
              <ul>
                <li>
                  <Link to='/privacy-policy'>Privacy policy</Link>
                </li>
                <li>
                  <Link to='/terms'>Terms of Services</Link>
                </li>
                {/* <li>
                  <Link to="/download">Download Game</Link>
                </li>
                <li>
                  <Link to="/brand-detail">Brand Game</Link>
                </li> */}
              </ul>
            </div>
            <div className='socail-links'>
              <h6>Follow us</h6>
              <ul>
                <li>
                  <Link to='#'>
                    <img src={facebook} alt='' />
                  </Link>
                </li>
                <li>
                  <Link to='#'>
                    <img src={youtube} alt='' />
                  </Link>
                </li>
                <li>
                  <Link to='#'>
                    <img src={telegram} alt='' />
                  </Link>
                </li>
              </ul>
              <div className='contact-us'>
                <h6>Contact us</h6>
                <a href='mailto:kingoffish@gmail.com'>
                  <img src={mail} alt='' /> kingoffish@gmail.com
                </a>
              </div>
            </div>
          </div>
          <div className='copyright-text'>
            <p>
              © 2022 - King of Fish Games <span>- All Rights Reserved</span>
            </p>
          </div>
          <div className='support-chat-wrapper' ref={wrapperRef}>
            <div className='support-icon'>
              <span
                role='presentation'
                onClick={() => handleClick(!show)}
                id='liveChat'>
                <img src={chaticon} alt='chat' />
              </span>
            </div>

            {show && <SupportChat show={show} handleClick={handleClick} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
