import { useState, useContext } from "react";
import { Button, Modal } from "react-bootstrap";
import coin from "../../../assets/images/wallet/depositcoin.svg";
import app from "../../../assets/images/wallet/cashapp.svg";
import card1 from "../../../assets/images/wallet/visa.svg";
import card2 from "../../../assets/images/wallet/master.svg";
import card3 from "../../../assets/images/wallet/maestro.svg";
import bit from "../../../assets/images/wallet/bitcoin.svg";
import WithdrawRequest from "./withdraw-request";
import UserContext from "../../../context/UserContext";
import { changeCurrencyFormat, errorMsgTimeout } from "../../../utils";
import { createTransaction } from "../../../utils/Api";
import linkicon from "../../../assets/images/table/link-icon.svg";

const WithdrawPopup = ({ handleWithShow, withdraw, setWithdraw }) => {
  const { user, setUser } = useContext(UserContext);
  const [copyState] = useState(user?.coin);
  const [steps, setSteps] = useState(0);
  const initialObj = {
    coin: "",
    amountInUsd: "",
    cryptoPrice: "",
    payment: "cashApp",
    status: "idle",
    userCoin: user?.coin || 0,
    address: "",
  };
  const [withdrawData, setWithdrawData] = useState(initialObj);
  const [withDrawReq, setWithDrawReq] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  console.log(withdrawData);
  const handleWithReq = async () => {
    const formData = new FormData();

    if (
      parseFloat(withdrawData.coin) > 0 &&
      parseFloat(withdrawData.coin) <= user?.coin
    ) {
      const payLoad = { ...withdrawData };
      payLoad.coin = parseInt(withdrawData.coin);
      formData.append("coin", payLoad.coin);
      formData.append("payment", payLoad.payment);
      const res = await createTransaction("withdraw", formData);
      if (res.code === 200) {
        setUser({ ...user, coin: withdrawData.userCoin });
        setSuccessMsg("Withdraw request send.");
        setTimeout(() => {
          setErrorMsg("");
          setSteps(0);
          setWithdraw(false);
          setWithDrawReq(true);
          setSuccessMsg("");
        }, 500);
      } else {
        setErrorMsg("Unable to deposit.");
        setTimeout(() => {
          setErrorMsg("");
        }, 500);
      }
    } else {
      setErrorMsg("Please enter correct amount of Coins.");
      setTimeout(() => {
        setErrorMsg("");
      }, errorMsgTimeout);
    }
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    const copywithdrawData = { ...withdrawData };
    copywithdrawData[name] = value;
    if (name === "coin") {
      copywithdrawData.amountInUsd = value * 1;
      copywithdrawData.cryptoPrice = value * 0.000014;
      copywithdrawData.userCoin = copyState - value;
    }
    setWithdrawData(copywithdrawData);
  };

  const handleClose = () => {
    setWithDrawReq(false);
    setWithdrawData(initialObj);
  };

  const checkAmount = () => {
    if (parseFloat(withdrawData.coin)) {
      setSteps(1);
      setErrorMsg("");
    } else {
      setErrorMsg("Please enter correct amount of Coins.");
    }
  };
  return (
    <div className="withdraw-popup">
      <div className="deposit-popup-wrapper">
        <Modal
          size="lg"
          className="coin-popup"
          show={withdraw}
          onHide={() => {
            handleWithShow();
            setSteps(0);
          }}
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            {steps === 0 ? (
              <div className="coin-popup-content">
                <h1>Withdraw</h1>
                <h3>Available balance</h3>
                <div className="withdraw-popup-money">
                  <img src={coin} alt="" />
                  <span>{changeCurrencyFormat(user?.coin || 0)}</span>
                </div>

                <div className="deposit-popup-content">
                  <div className="deposit-popup-input">
                    <input
                      type="number"
                      value={withdrawData.coin}
                      name="coin"
                      placeholder="Amount of coins you want to get"
                      className="form-control"
                      onChange={handleOnChange}
                    />
                    <div className="deposit-popup-money">
                      <img src={coin} alt="" />
                      <span>{withdrawData.userCoin}</span>
                    </div>
                  </div>
                </div>
                {errorMsg && (
                  <>
                    <h6 className="text-danger mt-2 pb-1">{errorMsg}</h6>
                  </>
                )}
                <div className="deposit-popup-transfer">
                  <h4>Please choose the type of payment you want to use</h4>
                  <div className="deposit-popup-transfer-wrapper">
                    <label htmlFor="cashapp1">
                      <div
                        className={`deposit-popup-transfer-box ${
                          withdrawData.payment === "cashApp" ? "active" : ""
                        }`}
                      >
                        <div className="deposit-transfer-box-top">
                          <div className="deposit-cash-app">
                            <input
                              type="radio"
                              id="cashapp1"
                              name="payment"
                              value="cashApp"
                              checked={
                                withdrawData?.payment === "cashApp"
                                  ? true
                                  : false
                              }
                              onChange={handleOnChange}
                            />
                             {" "}
                            <label htmlFor="cashapp1">
                              <img src={app} alt="" />
                              CashApp
                            </label>
                          </div>
                          <div className="deposit-coin">
                            <span>1 COIN = 1 USD</span>
                          </div>
                          <div className="deposit-card">
                            <img src={card1} alt="" />
                            <img src={card2} alt="" />
                            <img src={card3} alt="" />
                          </div>
                        </div>
                        <div className="deposit-transfer-box-bottom">
                          <p>Withdraw money with your Cash App</p>
                          <span>${withdrawData?.amountInUsd || 0}</span>
                        </div>
                      </div>
                    </label>
                    <label htmlFor="cashapp2">
                      <div
                        className={`deposit-popup-transfer-box ${
                          withdrawData.payment === "btc" ? "active" : "active"
                        }`}
                      >
                        <div className="deposit-transfer-box-top">
                          <div className="deposit-cash-app">
                            <input
                              type="radio"
                              id="cashapp2"
                              name="payment"
                              value="btc"
                              checked={
                                withdrawData?.payment === "btc" ? true : false
                              }
                              onChange={handleOnChange}
                            />
                              <label htmlFor="cashapp2">Crypto</label>
                          </div>
                          <div className="deposit-coin">
                            <span>1 COIN = 0.000047 BTC</span>
                          </div>
                          <div className="deposit-card">
                            <img src={bit} alt="" />
                          </div>
                        </div>
                        <div className="deposit-transfer-box-bottom">
                          <p>Withdraw money with your Crypto Wallet</p>
                          <span>BTC {withdrawData?.cryptoPrice}</span>
                        </div>
                      </div>
                    </label>
                    {errorMsg && (
                      <>
                        <h6 className="text-danger mt-2 pb-1">{errorMsg}</h6>
                      </>
                    )}
                    {successMsg && (
                      <>
                        <h6 className="text-success mt-2 pb-1">{successMsg}</h6>
                      </>
                    )}
                  </div>
                </div>

                <div className="game-box-btn">
                  <div className="transparent-btn">
                    <Button
                      onClick={() => {
                        handleWithShow();
                        setSteps(0);
                      }}
                    >
                      cancel
                    </Button>
                  </div>
                  <div className="red-btn">
                    <Button
                      onClick={() => {
                        checkAmount();
                      }}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="coin-popup-content">
                <h1>Withdraw</h1>
                <h3>Available balance</h3>
                <div className="withdraw-popup-money">
                  <img src={coin} alt="" />
                  <span>{changeCurrencyFormat(user?.coin || 0)}</span>
                </div>

                <div className="deposit-popup-content">
                  <div className="deposit-popup-input">
                    <input
                      type="text"
                      value={withdrawData.address}
                      name="address"
                      placeholder={
                        withdrawData.payment === "cashApp"
                          ? "Cash Tag"
                          : "BTC Address"
                      }
                      className="form-control"
                      onChange={handleOnChange}
                    />
                    <div className="deposit-popup-money-address">
                      <img src={linkicon} alt="" />
                    </div>
                  </div>
                </div>
                <div className="game-box-btn">
                  <div className="transparent-btn">
                    <Button
                      onClick={() => {
                        handleWithShow();
                        setSteps(0);
                      }}
                    >
                      cancel
                    </Button>
                  </div>
                  <div className="red-btn">
                    <Button onClick={handleWithReq}>Confirm</Button>
                  </div>
                </div>
              </div>
            )}
          </Modal.Body>
        </Modal>
      </div>
      <WithdrawRequest
        withDrawReq={withDrawReq}
        closePopup={handleClose}
        withdrawData={withdrawData}
      />
    </div>
  );
};

export default WithdrawPopup;
