import "./home.css";

const PageTitle = ({ titleIcon, pageTitle }) => {
  return (
    <div className="page-title">
      <h6>
        {/* <img src={homeIcon} alt="" /> HOME */}
        <img src={titleIcon} alt="" /> {pageTitle}
      </h6>
    </div>
  );
};

export default PageTitle;
