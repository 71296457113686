import { useRef,useEffect,useState } from "react";
import { Button } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Layout from "../layout/layout";
import "./game.css";
import BrandBreadcrumb from "./brand-breadcrumb";
import { useLocation, useNavigate } from "react-router-dom";
import popular7 from "../../assets/images/home/7.png";
import slide from "../../assets/images/game/download-banner.png";
import { getGames, getSingleGame } from "../../utils/Api";
import { openGameLink } from "../../utils";

const GameDownload = () => {
  const location = useLocation();
  const navigate = useNavigate()
  const { search } = location;
  const gameID = new URLSearchParams(search).get("game");
  useEffect(() => {
    const getSingleData = async()=>{
       const res = await getSingleGame(gameID);
       if(res.data.code === 200){
         setSelectedGame(res.data.game)
       }
    }
    getSingleData()
  }, [gameID])
  const [selectedGame,setSelectedGame] = useState([])
  const initialPageValue ={
    limit :12,
    page:1,
    sort: '-createdAt',
    gamesCat: selectedGame.gameCategory
   }
  const [releventGames,setReleventGames] = useState([])
  const [paginationValue,setPaginationValue] = useState(initialPageValue);

  const handleSelectGame =(data,index)=>{
    window.scrollTo(0, 0);
    const copyRelventGames = JSON.parse(JSON.stringify(releventGames));
    copyRelventGames[index] = selectedGame;
    setReleventGames(copyRelventGames);
    setSelectedGame(data);
  }
  
  useEffect(()=>{
    const getReleventGame = async()=>{
      const res = await getGames(
       {
        limit :12,
        page:1,
        sort: '-createdAt',
        gamesCat: selectedGame.gameCategory,
        gameId:  selectedGame.id
       }
      );
      if (res.status === 200) {
        const { results, limit, page, totalPages, totalResults } =
          res?.data?.game;
          setReleventGames(results || []);
        const pageData = { limit, page, totalPages, totalResults, sort:paginationValue.sort,gamesCat:paginationValue.gamesCat  };
        if (JSON.stringify(paginationValue) !== JSON.stringify(pageData)) {
          setPaginationValue(pageData);
        }
      }
    }
    getReleventGame();
  },[selectedGame.gameCategory, selectedGame.id, paginationValue])

  return (
    <Layout>
      <div className="game-download-page">
        <div className="container">
          {selectedGame && <>
            <BrandBreadcrumb data={selectedGame}/>
          <GamesDownloadSlider gameDetail={selectedGame}/>
          </>}
          
          <div className="brand-detail-section">
            <h3>{selectedGame?.name||''}</h3>
            <p>
             {selectedGame?.description||''}
            </p>
            <Button onClick={()=>openGameLink(selectedGame?.downloadUrl,selectedGame?.id,navigate)}>Download</Button>
          </div>
          <div className="relevent-game-section">
            <div className="section-title">
              <h6>Relevant games</h6>
            </div>
            <div className="relevent-game-content">
              <div className="popular-small-grid">
                {releventGames.length>0 && releventGames.map((el,i)=>(
                  <>
                   <div key={el?.id ?? el?._id} className="popular-small-box" onClick={()=>handleSelectGame(el,i)}>
                  <img src={el.logo||popular7} alt="popular" />
                </div>
                  </>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default GameDownload;

const GamesDownloadSlider = ({gameDetail}) => {
  const customeSlider = useRef();
  const previous = () => {
    customeSlider.current.slickPrev();
  };

  const next = () => {
    customeSlider.current.slickNext();
  };
  const settings = {
    dots: true,
    cssEase: "linear",
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          autoplay: true,
        },
      },
    ],
    appendDots: (dots) => <ul>{dots}</ul>,
    customPaging: (i) => (
      <div className="slick_dots_custom">
        <img src={gameDetail.bannerImages[i]} alt="slides" />
      </div>
    ),
  };
  return (
    <div className="download-games-slider">
      <h2>{gameDetail?.name||''} - {gameDetail?.brandName?.name||''} </h2>
      <Slider {...settings} ref={customeSlider}>
        {gameDetail?.bannerImages?.length>0 && gameDetail?.bannerImages?.map((el)=>(
          <div key={el?.id ?? el?._id}>
            <div className="download-game-tiles">
          <div className="download-game-box">
            <img src={el||slide} alt="game-pic" />
          </div>
           </div>
          </div>
        )) }
      </Slider>
      <div className="custom-arrow">
        <span className="pre-btn" onClick={previous}>
          <i class="las la-long-arrow-alt-left"></i>
        </span>
        <span className="next-arrow" onClick={next}>
          <i class="las la-long-arrow-alt-right"></i>
        </span>
      </div>
    </div>
  );
};
