import { Button } from "react-bootstrap";
import "./home.css";
import PageTitle from "./pageTitle";
import homeIcon from "../../assets/images/home/homeIcon.svg";
import { useContext } from "react";
import UserContext from "../../context/UserContext";

const HeroBanner = () => {
  const {landingPagesImg} = useContext(UserContext);
  return (
    <div className="hero-banner" style={{background: `${landingPagesImg?.homePageImg ? `url(${landingPagesImg?.homePageImg})` : ''}`}}>
      <div className="container">
        <div className="hero-content">
          <PageTitle pageTitle="Home" titleIcon={homeIcon} />
          <div className="home-grid">
            <div className="home-left">
              <h1>Play on any device, anytime, anywhere!</h1>
              <p>The Best Online Social Gaming</p>
              <Button>Play now</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroBanner;
