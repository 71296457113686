import "./pagenotfound.css";
import { Link } from "react-router-dom";
import brandLogo from "../../../src/assets/images/header/logo3d.png";
import image404 from "../../../src/assets/images/404.svg";

const PageNotFound = () => {
  return (
    <>
      <div className="page-not-found">
        <div className="container">
          <div className="stars">
            <div className="custom-navbar">
              <Link to="/" className="brand-logo">
                <img src={brandLogo} alt="" width="80px" />
              </Link>
            </div>
            <div className="central-body">
              <img className="image-404" src={image404} alt="" width="300px" />
              <h6>Oops! The page you were looking for does not exist.</h6>
              <p>
                You may have miss typed the address or the page may have moved.
              </p>
              <Link to="/" className="btn-go-home">
                GO BACK HOME
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PageNotFound;
