import {useState,useEffect} from 'react'
import { Button } from "react-bootstrap";
import card from "../../assets/images/about/prize.svg";
import pc from "../../assets/images/about/support.svg";
import diamond from "../../assets/images/about/security.svg";
// import playgame1 from "../../assets/images/about/pg1.png";
// import playgame2 from "../../assets/images/about/pg2.png";
// import playgame3 from "../../assets/images/about/pg3.png";
// import playgame4 from "../../assets/images/about/pg4.png";
// import playgame5 from "../../assets/images/about/pg5.png";
import { getGames } from '../../utils/Api';
import { Link } from 'react-router-dom';

const AboutFeature = () => {
  const [games,setGames] = useState([]);
  const cssArr = ['pg-top-left-pic','pg-top-right-pic','pg-center-pic','pg-bottom-left-pic','pg-bottom-right-pic']
  useEffect(() => {
    const getGamesList = async ()=>{
      const res = await getGames(
        {
          limit: 5,
          page: 1,
          sort:  '-createdAt',
          gamesCat: 'all'
        }
      );
      if (res.status === 200) {
        const { results} =
        res?.data?.game;
        setGames(results || []);
      }
    }
    getGamesList()
  }, [])
  
  return (
    <div className="about-feature-section">
      <div className="container">
        <div className="our-feature">
          <div className="section-title">
            <h6>our Features</h6>
          </div>
          <div className="our-feature-grid">
            <div className="our-feature-box">
              <img src={card} alt="" />
              <h6>Daily Prizes</h6>
              <p>
                Every day we provide you with various bonuses and coupons that
                will make your play even cooler
              </p>
            </div>
            <div className="our-feature-box">
              <img src={pc} alt="" />
              <h6>Quick Support</h6>
              <p>
                Our support managers will assist you 24/7, and help you with any
                questions you have.
              </p>
            </div>
            <div className="our-feature-box">
              <img src={diamond} alt="" />
              <h6>Cash Security</h6>
              <p>
                We use proven word known payment systems, so you don’t need to
                bother about your money. Just enjoy the play.
              </p>
            </div>
          </div>
        </div>
        <div className="play-game-section">
          <div className="section-title">
            <h6>For gamers</h6>
          </div>
          <div className="play-game-grid">
            <div className="play-game-left">
              <div className="pg-left-content">
                <h2>King of Fish Games was created with you in mind</h2>
                <p>
                  If you’re a gamer and love the excitement found in fish table
                  games, you won’t be able to get enough of our completely
                  mobile app! Hone the skills required to master fish games and
                  beat the competition with the app that can be played on your
                  smartphone at any time.
                </p>
                <p>
                  With numerous different fish games to choose from, such as
                  Fire Kirin Plus, Dragon Slayer, Ocean King 5 games, and more,
                  your phone will become your new favorite arcade
                </p>
                <div className="pg-tags">
                  <h6>Any Device</h6>
                  <h6>Any Time</h6>
                  <h6>Any Where</h6>
                  <h6>Adaptive</h6>
                </div>
                <Link to='/games'>
                <Button>Play games </Button>
                </Link>
                
              </div>
            </div>
            <div className="play-game-right">
              <div className="play-game-images">
                {games.length>0 && <>
                  { games.map((el,i)=>(
                    <div className={cssArr[i]} key={el?.id ?? el?._id}>
                  <img src={el?.logo} alt={el?.name} />
                </div>
                  ))
                }
                </>}
                              
                {/* <div className="pg-top-left-pic">
                  <img src={playgame2} alt="" />
                </div>
                <div className="pg-top-right-pic">
                  <img src={playgame3} alt="" />
                </div>
                <div className="pg-center-pic">
                  <img src={playgame1} alt="" />
                </div>
                <div className="pg-bottom-left-pic">
                  <img src={playgame4} alt="" />
                </div>
                <div className="pg-bottom-right-pic">
                  <img src={playgame5} alt="" />
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutFeature;
