import "./header.css";
import {  Button } from "react-bootstrap";
import bellicon from "../../assets/images/header/bell-icon.svg";



const NotificationIcon = ({ handleNotification ,showBellNotify}) => {
    return <>
      <Button className='notification-btn' onClick={() => handleNotification()}>
       <img src={bellicon} alt='bell icon' />
       {showBellNotify && <span className='red-icon'></span>}
      </Button>
    </>
  };

export default NotificationIcon;