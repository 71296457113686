import { useEffect } from "react";
import "./layout.css";
import ClientMenu from "../menu/menu";
import ClientHeader from "../clientheader/header";

const ClientLayout = ({ children }) => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="main-wrapper">
      <div className="admin-content-view">
        <ClientHeader />

        <div className="admin-wrapper">
          <ClientMenu />

          <div className="admin-container">
            <div className="client-content">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientLayout;
