import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./home.css";
import card from "../../assets/images/home/card.svg";
import pc from "../../assets/images/home/pc.svg";
import diamond from "../../assets/images/home/diamond.svg";
import ultra from "../../assets/images/home/ultra.png";
// import vpower from "../../assets/images/home/vpower.png";
// import orion from "../../assets/images/home/orion.png";
import remote from "../../assets/images/home/remote.svg";

const WhyUs = ({ brands }) => {
  return (
    <div className='why-us-section'>
      <div className='container'>
        <div className='our-feature'>
          <div className='section-title'>
            <h6>The benefits of joining our community</h6>
          </div>

          <h2>
            We’ve got free play, bonuses, community prizes, multipliers, lasers,
            and many extra features.{" "}
          </h2>
          <div className='our-feature-grid'>
            <div className='our-feature-box'>
              <img src={card} alt='' />
              <h6>Play Casino’s Games Anywhere, Anytime.</h6>
              <p>
                Join us now to play casino-style games like Slots, Keno, Fish
                and many more, right where you are.
              </p>
            </div>
            <div className='our-feature-box'>
              <img src={pc} alt='' />
              <h6>Available on PC & Mobile</h6>
              <p>
                Available on PC & Mobile All our games are playable on pc as
                well as mobile. Download now to get right into the action.
              </p>
            </div>
            <div className='our-feature-box'>
              <img src={diamond} alt='' />
              <h6>Special Offers</h6>
              <p>
                We provide free play bonuses, exciting deposit offers, referral
                awards, grand prizes to jackpot winners and many more.
              </p>
            </div>
          </div>
        </div>
        <div className='games-brand'>
          <div className='section-title'>
            <h6>Providers</h6>
          </div>
          {brands.length > 0 && <GamesBrandSlider brands={brands} />}
          <div className='section-title section-link'>
            <Link to='/games?tabs=brands'>Show me more</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyUs;

const GamesBrandSlider = ({ brands }) => {
  const settings = {
    dots: true,
    cssEase: "linear",
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          autoplay: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          autoplay: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          autoplay: true,
        },
      },
    ],
  };
  return (
    <div className='games-brand-slider hero-slider'>
      <Slider {...settings}>
        {brands &&
          brands.length > 0 &&
          brands.map((el) => (
            <>
              <div className='games-brand-tiles' key={el?.id ?? el?._id}>
                <div className='games-brand-box'>
                  <img src={el?.brandDetail[0]?.logo || ultra} alt='slider-pic' />
                  <div className='game-name'>
                    <h6>{el?.brandDetail[0]?.name || ""}</h6>
                    <p>
                      <img src={remote} alt='game' /> {el?.totalgames || 0}{" "}
                      Games
                    </p>
                  </div>
                  <div className='game-info'>
                    <h5>{el?.brandDetail[0]?.name || ""}</h5>
                    <p>{el?.brandDetail[0]?.description || ""}</p>
                    <div className='games-brand-btn'>
                      {/* <Button>Download</Button> */}
                      <Link to={`/brand-detail?brand=${el?._id}`}>About</Link>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ))}
      </Slider>
    </div>
  );
};
