import { Link } from "react-router-dom";
import remote from "../../assets/images/home/remote.svg";
import firekirin from "../../assets/images/home/firekirin.png";
import PagePagination from "../pagination/Pagination";

const BrandGamesContent = ({handlePagination, brands, paginationValue}) => {
  return (
    <div className="brand-games-content">
      <div className="brand-game-grid">
        {brands.length > 0 && brands.map((el)=>(
          <>
           <div className="games-brand-box" key={el?.id ?? el?._id}>
          <img src={el?.brandDetail[0]?.logo || firekirin} alt="slider-pic" />
          <div className="game-name">
            <h6>{el?.brandDetail[0]?.name || ''}</h6>
            <p>
              <img src={remote} alt="game" /> {el?.totalgames||0} Games
            </p>
          </div>
          <div className="game-info">
            <h5>{el?.brandDetail[0]?.name || ''}</h5>
            <p>
            {el?.brandDetail[0]?.description || ''}
            </p>
            <div className="games-brand-btn">
              {/* <Button>Download</Button> */}
              <Link to={`/brand-detail?brand=${el._id}`}>About</Link>
            </div>
          </div>
        </div>
          </>
        ))}
      </div>
      {paginationValue?.totalPages > 1 && 
      <PagePagination handlePagination={handlePagination}  page={paginationValue?.page} totalpages={paginationValue?.totalPages}/>
}
    </div>
  );
};

export default BrandGamesContent;
