import Footer from "../footer";
import Header from "../header";
import { useEffect } from "react";

const Layout = ({ children }) => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="main-wrapper">
      <div className="wrapper">
        <Header />
        <div className="content">{children}</div>
        <Footer />
      </div>
    </div>
  );
};

export default Layout;
