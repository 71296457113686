import React from "react";
import { Form, Modal } from "react-bootstrap";

function KycNotCompletedPopup({ setIsKyc, closePopup, isKyc }) {
  return (
    <Modal
      size="lg"
      className="game-request-popup"
      show={isKyc}
      onHide={() => setIsKyc(false)}
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="new-game-wrapper">
          <Form>
            <div className="game-request-heading my-5">
              <h6>Your KYC is not verified, please make sure to verify your identity in order to access to our other features</h6>
            </div>
          </Form>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default KycNotCompletedPopup;
