import Layout from "../layout/layout";
import GamesBanner from "./game-banner";
import GameTabs from "./game-tabs";
import "./game.css";

const Games = () => {
  return (
    <Layout>
      <div className="game-page">
        <GamesBanner />
        <GameTabs />
      </div>
    </Layout>
  );
};

export default Games;
