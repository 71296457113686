import Layout from "../layout/layout";
import "./privacy.css";

const PrivacyPolicy = () => {
  return (
    <Layout>
      <div className="privacy-policy">
        <div className="container">
          <div className="legal-page-heading">
            <h1>Privacy Policy</h1>
          </div>
          <div className="legal-content-body">
            <div className="legal-content-wrapper">
              <div className="legal-subheading">
                <h5>
                  <span>01</span>Introduction
                </h5>
              </div>
              <div className="legal-paragraph">
                <p>
                  These Website Standard Terms and Conditions written on this
                  webpage shall manage your use of our website, King of Fish
                  Games accessible at kingoffishgames.net
                </p>
                <p>
                  These Terms will be applied fully and affect to your use of
                  this Website. By using this Website, you agreed to accept all
                  terms and conditions written in here. You must not use this
                  Website if you disagree with any of these Website Standard
                  Terms and Conditions.
                </p>
                <p>
                  Minors or people below 18 years old are not allowed to use
                  this Website.
                </p>
              </div>
            </div>
            <div className="legal-content-wrapper">
              <div className="legal-subheading">
                <h5>
                  <span>02</span>Intellectual Property Rights
                </h5>
              </div>
              <div className="legal-paragraph">
                <p>
                  Other than the content you own, under these Terms, King of
                  Fish Games and/or its licensors own all the intellectual
                  property rights and materials contained in this Website.
                </p>
                <p>
                  You are granted limited license only for purposes of viewing
                  the material contained on this Website.
                </p>
              </div>
            </div>
            <div className="legal-content-wrapper">
              <div className="legal-subheading">
                <h5>
                  <span>03</span>Restrictions
                </h5>
              </div>
              <div className="legal-paragraph">
                <h6>
                  You are specifically restricted from all of the following:
                </h6>
                <ul>
                  <li>publishing any Website material in any other media;</li>
                  <li>
                    selling, sublicensing and/or otherwise commercializing any
                    Website material;
                  </li>
                  <li>
                    publicly performing and/or showing any Website material;
                  </li>
                  <li>
                    using this Website in any way that is or may be damaging to
                    this Website;
                  </li>
                  <li>
                    using this Website in any way that impacts user access to
                    this Website;
                  </li>
                  <li>
                    using this Website contrary to applicable laws and
                    regulations, or in any way may cause harm to the Website, or
                    to any person or business entity;
                  </li>
                  <li>
                    engaging in any data mining, data harvesting, data
                    extracting or any other similar activity in relation to this
                    Website;
                  </li>
                  <li>
                    using this Website to engage in any advertising or
                    marketing.
                  </li>
                </ul>
                <p>
                  Certain areas of this Website are restricted from being access
                  by you and King of Fish Games may further restrict access by
                  you to any areas of this Website, at any time, in absolute
                  discretion. Any user ID and password you may have for this
                  Website are confidential and you must maintain confidentiality
                  as well
                </p>
              </div>
            </div>
            <div className="legal-content-wrapper">
              <div className="legal-subheading">
                <h5>
                  <span>04</span>Your Content
                </h5>
              </div>
              <div className="legal-paragraph">
                <p>
                  In these Website Standard Terms and Conditions, “Your Content”
                  shall mean any audio, video text, images or other material you
                  choose to display on this Website. By displaying Your Content,
                  you grant Company Name a non-exclusive, worldwide irrevocable,
                  sub licensable license to use, reproduce, adapt, publish,
                  translate and distribute it in any and all media.
                </p>
                <p>
                  Your Content must be your own and must not be invading any
                  third-party's rights. King of Fish Games reserves the right to
                  remove any of Your Content from this Website at any time
                  without notice.
                </p>
              </div>
            </div>
            <div className="legal-content-wrapper">
              <div className="legal-subheading">
                <h5>
                  <span>05</span>No warranties
                </h5>
              </div>
              <div className="legal-paragraph">
                <p>
                  This Website is provided “as is,” with all faults, and King of
                  Fish Games express no representations or warranties, of any
                  kind related to this Website or the materials contained on
                  this Website. Also, nothing contained on this Website shall be
                  interpreted as advising you.
                </p>
              </div>
            </div>
            <div className="legal-content-wrapper">
              <div className="legal-subheading">
                <h5>
                  <span>06</span>Limitation of liability
                </h5>
              </div>
              <div className="legal-paragraph">
                <p>
                  In no event shall King of Fish Games, nor any of its officers,
                  directors and employees, shall be held liable for anything
                  arising out of or in any way connected with your use of this
                  Website whether such liability is under contract. King of Fish
                  Games, including its officers, directors and employees shall
                  not be held liable for any indirect, consequential or special
                  liability arising out of or in any way related to your use of
                  this Website.
                </p>
              </div>
            </div>
            <div className="legal-content-wrapper">
              <div className="legal-subheading">
                <h5>
                  <span>07</span>Indemnification
                </h5>
              </div>
              <div className="legal-paragraph">
                <p>
                  You hereby indemnify to the fullest extent King of Fish Games
                  from and against any and/or all liabilities, costs, demands,
                  causes of action, damages and expenses arising in any way
                  related to your breach of any of the provisions of these
                  Terms.
                </p>
              </div>
            </div>
            <div className="legal-content-wrapper">
              <div className="legal-subheading">
                <h5>
                  <span>08</span>Severability
                </h5>
              </div>
              <div className="legal-paragraph">
                <p>
                  If any provision of these Terms is found to be invalid under
                  any applicable law, such provisions shall be deleted without
                  affecting the remaining provisions herein.
                </p>
              </div>
            </div>
            <div className="legal-content-wrapper">
              <div className="legal-subheading">
                <h5>
                  <span>09</span>Variation of Terms
                </h5>
              </div>
              <div className="legal-paragraph">
                <p>
                  King of Fish Games is permitted to revise these Terms at any
                  time as it sees fit, and by using this Website you are
                  expected to review these Terms on a regular basis.
                </p>
              </div>
            </div>
            <div className="legal-content-wrapper">
              <div className="legal-subheading">
                <h5>
                  <span>10</span>Assignment
                </h5>
              </div>
              <div className="legal-paragraph">
                <p>
                  These Terms constitute the entire agreement between King of
                  Fish Games and you in relation to your use of this Website,
                  and supersede all prior agreements and understandings.
                </p>
              </div>
            </div>
            <div className="legal-content-wrapper">
              <div className="legal-subheading">
                <h5>
                  <span>11</span>Governing Law & Jurisdiction
                </h5>
              </div>
              <div className="legal-paragraph">
                <p>
                  These Terms will be governed by and interpreted in accordance
                  with the laws of the State of Country, and you submit to the
                  non-exclusive jurisdiction of the state and federal courts
                  located in Country for the resolution of any disputes.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PrivacyPolicy;
