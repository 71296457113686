import {useState} from 'react'
import { Form, Button, FormControl } from "react-bootstrap";
import useDebounce from "../../../hooks/useDebounce";
import "./search.css";

const SearchBoxComponent = ({searchFunction}) => {
  const [search, setSearch] = useState("");
  const [loader] = useDebounce(search, searchFunction, 500);

  const handleChnage = (e) => {
    setSearch(e.target.value);
  };

  console.log({ loader });
  return (
    <div className="admin-search-wrapper">
      <div className="search-box">
        <Form className="search-box-form">
          <Button>
            <i className="las la-search" />
          </Button>
          <FormControl type="search" placeholder="Search" aria-label="Search" onChange={handleChnage}/>
        </Form>
      </div>
    </div>
  );
};

export default SearchBoxComponent;
