import {useContext} from 'react'
import PageTitle from "../home/pageTitle";
import offerIcon from "../../assets/images/offer/offer-icon.svg";
import UserContext from "../../context/UserContext";

const OfferBanner = () => {
  const {landingPagesImg} = useContext(UserContext);
  return (
    <div className="offer-banner" style={{background: `${landingPagesImg?.offerPageImg ? `url(${landingPagesImg?.offerPageImg})` : ''}`}}>
      <div className="container">
        <PageTitle pageTitle="Offers" titleIcon={offerIcon} />

        <div className="banner-grid">
          <div className="banner-left">
            <h1>Enjoy the offers Exclusively For You!</h1>
            <p>Get latest offers and coupons directly and start Playing Now</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OfferBanner;
