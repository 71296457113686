import {useState,useEffect} from 'react'
import { Button, Dropdown, Tabs, Tab } from "react-bootstrap";
import Layout from "../layout/layout";
import "./game.css";
import banner from "../../assets/images/game/brand-banner.png";
import brandlogo from "../../assets/images/game/brand-logo.png";
import remote from "../../assets/images/home/remote.svg";
import xgame from "../../assets/images/game/x-game.png";
import vpower from "../../assets/images/game/v-power.png";
import panda from "../../assets/images/game/panda.png";
import rs from "../../assets/images/game/rs.png";
import kirin from "../../assets/images/game/kirin.png";
import tabicon1 from "../../assets/images/game/tab-icon1.svg";
import tabicon2 from "../../assets/images/game/tab-icon2.svg";
import tabicon3 from "../../assets/images/game/tab-icon3.svg";
import tabicon5 from "../../assets/images/game/tab-icon5.svg";
import tabicon6 from "../../assets/images/game/tab-icon6.svg";
import tabicon7 from "../../assets/images/game/tab-icon7.svg";
import tabicon8 from "../../assets/images/game/tab-icon8.svg";
import AllGamesContent from "./all-games-tab";
import BrandBreadcrumb from "./brand-breadcrumb";
import { useLocation } from "react-router-dom";
import { getBrandsByGame, getGames } from '../../utils/Api';

const BrandDetail = () => {
  const location = useLocation();
  const { search } = location;
  const brandId = new URLSearchParams(search).get("brand");
  useEffect(() => {
    const getSingleData = async()=>{
       const res = await getBrandsByGame({limit:0,brandId:brandId,page:1});
       if(res.status === 200){
        setBrandDetail(res.data.results[0]|| {})
       }
    }
    getSingleData()
  }, [brandId])

  const [brandDetail,setBrandDetail] = useState(null)

  // For the game-------->>>>>
  const initialPaginationValue = {
    limit: 0,
    page: 0,
    totalPages: 0,
    totalResults: 0,
    sort:  '-createdAt',
    gamesCat: 'all',
    brandName:brandId,
  };
  const [games,setGames] = useState([]);
  const [gamesView, setgamesView] = useState('');
  const [paginationValue, setPaginationValue] = useState(
    initialPaginationValue
  );
  // console.log('games---',games);
  useEffect(() => {
    const getGamesByType = async () => {
        const res = await getGames(
          paginationValue
        );
        if (res.status === 200) {
          const { results, limit, page, totalPages, totalResults } =
            res?.data?.game;
            setGames(results || []);
          const pageData = { limit, page, totalPages, totalResults, sort:paginationValue.sort,gamesCat:paginationValue.gamesCat,brandName:brandId  };
          if (JSON.stringify(paginationValue) !== JSON.stringify(pageData)) {
            setPaginationValue(pageData);
          }
        }
    };
    getGamesByType();
  }, [paginationValue, gamesView, brandId]);

  const handlegamesView = (tab) => {
    const copyPagination = {...paginationValue};
    copyPagination.page = 1;
    if(tab==='-playCount,-createdAt'){
      copyPagination.gamesCat = ''
      copyPagination.sort = tab
    }else if(!tab){
      copyPagination.gamesCat = ''
      copyPagination.sort = '-createdAt'
    }else{
      copyPagination.gamesCat = tab
      copyPagination.sort = '-createdAt'
    }
    setPaginationValue(copyPagination);
    setgamesView(tab);
  };
  const handlePagination = (pagevalue) => {
    const copypagination = { ...paginationValue };
    if (pagevalue <= copypagination.totalPages) {
      copypagination.page = pagevalue;
    }
    setPaginationValue(copypagination);
  };

  return (
    <Layout>
      <div className="brand-detail-page">
        <div className="brand-banner-section">
          <div className="container">
            <BrandBreadcrumb data={brandDetail}/>
            <div className="brand-banner-image">
              <img src={brandDetail?.brandDetail?.[0]?.bannerImages?.[0] || banner} alt="banner" className="img-fluid" />
              <div className="brand-info-section">
                <div className="brand-info-grid">
                  <div className="brand-info-left">
                    <div className="brand-logo">
                      <img src={brandDetail?.brandDetail?.[0]?.logo || brandlogo} alt="logo" />
                    </div>
                    <div className="brand-info-name">
                      <h6>{brandDetail?.brandDetail[0]?.name||''}</h6>
                      <p>
                        <img src={remote} alt="game" /> {brandDetail?.totalgames || ''} Games
                      </p>
                    </div>
                  </div>
                  <div className="brand-info-right">
                    <div className="dropdown-area">
                      <Dropdown>
                        <Dropdown.Toggle id="dropdown-basic">
                          <img src={brandlogo} alt="logo" /> Milky Way
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <div className="brand-info-container">
                            <Dropdown.Item href="#/action-1">
                              <img src={xgame} alt="logo" /> X Games
                            </Dropdown.Item>
                            <Dropdown.Item href="#/action-2">
                              <img src={vpower} alt="logo" /> V Power
                            </Dropdown.Item>
                            <Dropdown.Item href="#/action-3">
                              <img src={panda} alt="logo" /> Master Panda
                            </Dropdown.Item>
                            <Dropdown.Item href="#/action-2">
                              <img src={rs} alt="logo" /> Riversweeps
                            </Dropdown.Item>
                            <Dropdown.Item href="#/action-3">
                              <img src={kirin} alt="logo" /> Fire Kirin
                            </Dropdown.Item>
                            <Dropdown.Item href="#/action-1">
                              <img src={xgame} alt="logo" /> X Games
                            </Dropdown.Item>
                            <Dropdown.Item href="#/action-2">
                              <img src={vpower} alt="logo" /> V Power
                            </Dropdown.Item>
                          </div>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="brand-detail-section">
              <h2>{brandDetail?.brandDetail[0]?.name||''}</h2>
              <p>
              {brandDetail?.brandDetail[0]?.description||''}
              </p>
              <Button>Download</Button>
            </div>
          </div>

          <div className="brand-detail-tabs">
            <div className="container">
              <div className="games-tab-grid">
                <Tabs defaultActiveKey="all" id="uncontrolled-tab-example" onSelect={(tab) => handlegamesView(tab)}>
                  <Tab
                    eventKey="all"
                    title={
                      <h6>
                        <img src={tabicon1} alt="tab-icon" />
                        All Games
                      </h6>
                    }
                  >
                    <AllGamesContent  handlePagination={handlePagination} games={games} paginationValue={paginationValue}/>
                  </Tab>
                  <Tab
                      eventKey="-playCount,-createdAt"
                    title={
                      <h6>
                        <img src={tabicon2} alt="tab-icon" />
                        Popular
                      </h6>
                    }
                  >
                    <AllGamesContent  handlePagination={handlePagination} games={games} paginationValue={paginationValue}/>
                  </Tab>
                  <Tab
                   eventKey="-createdAt"
                    title={
                      <h6>
                        <img src={tabicon3} alt="tab-icon" />
                        New
                      </h6>
                    }
                  >
                    <AllGamesContent  handlePagination={handlePagination} games={games} paginationValue={paginationValue}/>
                  </Tab>

                  <Tab
                    eventKey="keno"
                    title={
                      <h6>
                        <img src={tabicon5} alt="tab-icon" />
                        Keno
                      </h6>
                    }
                  >
                    <AllGamesContent  handlePagination={handlePagination} games={games} paginationValue={paginationValue}/>
                  </Tab>
                  <Tab
                    eventKey="fish"
                    title={
                      <h6>
                        <img src={tabicon6} alt="tab-icon" />
                        Fish
                      </h6>
                    }
                  >
                    <AllGamesContent  handlePagination={handlePagination} games={games} paginationValue={paginationValue}/>
                  </Tab>
                  <Tab
                    eventKey="slots"
                    title={
                      <h6>
                        <img src={tabicon7} alt="tab-icon" />
                        Slots
                      </h6>
                    }
                  >
                    <AllGamesContent  handlePagination={handlePagination} games={games} paginationValue={paginationValue}/>
                  </Tab>
                  <Tab
                    eventKey="others"
                    title={
                      <h6>
                        <img src={tabicon8} alt="tab-icon" />
                        Others
                      </h6>
                    }
                  >
                    <AllGamesContent  handlePagination={handlePagination} games={games} paginationValue={paginationValue}/>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default BrandDetail;
