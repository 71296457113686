import "./pageheader.css";
import coinicon from "../../../assets/images/header/coin-icon.svg";
import { Button } from "react-bootstrap";
import { useState,useContext ,useEffect} from "react";
import UserContext from "../../../context/UserContext";
import DepositPopup from "../depositpopup/depositpopup";
import WithdrawPopup from "../withdraw-popup/withdrawpopup";
import { getAdminCashApp } from "../../../utils/Api";
import { changeCurrencyFormat } from "../../../utils";

const PageHeader = ({ pageTitle, btnTitle }) => {
  const { user,setAdminDetail } = useContext(UserContext);
  const [deposit, setDeposit] = useState(false);

  const handleBtnClick =(transactionType)=>{
    if(transactionType==='Deposit money'){
      handleShow()
    }else if(transactionType==='Withdraw money'){
      handleWithShow()
    }
  }
  
  const handleShow = () => setDeposit(true);
  const handleClose =()=>{
    setDeposit(false)
  }
  const [withdraw, setWithdraw] = useState(false);
  const handleWithShow = () =>{ 
    setWithdraw(!withdraw)
  };

  useEffect(() => {
    const getAdminData = async()=>{
      const res = await getAdminCashApp();
      if(res.code===200){
        setAdminDetail(res.userData)
      }
    }
    getAdminData()
  },[setAdminDetail])

  return (
    <>
    <div className="page-header">
      <div className="container">
        <div className="page-header-title">
          <h2>{pageTitle}</h2>
          <p>Wallet Balance</p>
        </div>
        <div className="wallet-info">
          <div className="total-coins">
            <p>
              <img src={coinicon} alt="coin" /> {changeCurrencyFormat(user?.coin||0)}
            </p>
          </div>
          <Button onClick={()=>handleBtnClick(btnTitle)}> {btnTitle}</Button>
        </div>
      </div>
    </div>
    <DepositPopup
      deposit={deposit}
      handleClose={handleClose}
      setDeposit={setDeposit}
    
    />
    <WithdrawPopup
      withdraw={withdraw}
      handleWithShow={handleWithShow}
      setWithdraw={setWithdraw}
    />
    </>
  );
};

export default PageHeader;
