import { useEffect, useState } from "react"

const useDebounce = (search, cb, time) => {
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        let timeOut;
        (() => {  
        setLoading(false);   
        timeOut = setTimeout(async() => {
            await cb(search)
            setLoading(false);   
        }, time); 
        })();
        return () => {
            if(timeOut){
                clearTimeout(timeOut);
            }
        }
    }, [cb, search, time]);
    return [loading]
}

export default useDebounce;