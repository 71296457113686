import { useContext, useState } from "react";
import { Modal, Button, Spinner } from "react-bootstrap";
import "./delete-popup.css";
import { updateUser } from "../../../utils/Api";
import UserContext from "../../../context/UserContext";
import user from "../../../assets/images/setting/user-delete.svg";
import toast from "react-hot-toast";
import { Navigate } from "react-router-dom";
import { errorMsgTimeout } from "../../../utils";

const DeletePopup = ({ handleDeletePopup, deletePopup }) => {
  const {
    user: { coin },
    setUser,
    setTokens,
    setKycStatus
  } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const handleLogout = () => {
    localStorage.removeItem("token");
    setUser(null);
    setTokens({});
    setKycStatus("");

    Navigate("/login");
  };
  const deleteAccount = async () => {
    if (coin <= 0) {
      setLoading(true);
      const res = await updateUser({ isDelete: true });
      setLoading(false);
      if (res.status === 200) {
        toast.success("Account Deleted Successfully.");
        setTimeout(() => {
          handleLogout();
        }, errorMsgTimeout);
      }
    } else {
      setError("Please withdraw your all credits");
      setTimeout(() => {
        setError("");
        handleDeletePopup();
      }, errorMsgTimeout);
    }
  };
  return (
    <div className='delete-popup-wrapper'>
      <Modal
        size='lg'
        className='delete-popup'
        show={deletePopup}
        onHide={() => handleDeletePopup()}
        centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className='delete-popup-content'>
            <img src={user} alt='icon' />
            <h6>Please confirm if you want to delete your account</h6>
            <p>After you confirm, all of your games account will be deleted.</p>
            {error && <p className='text-danger'>{error}</p>}

            <div className='delete-popup-footer'>
              <Button variant='secondary' onClick={handleDeletePopup}>
                Cancel
              </Button>
              <Button variant='primary' onClick={() => deleteAccount()}>
                {loading ? <Spinner animation='border' /> : "Confirm"}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default DeletePopup;
