import { useEffect } from "react";
import Layout from "../layout/layout";
import HeroBanner from "./hero";
import HeroSlider from "./heroSlider";
import "./home.css";
import OfferSection from "./offer";
import PopularGames from "./popularGame";
import Testimonial from "./testimonial";
import WhyUs from "./whyUs.jsx";
import { socket } from "../../config/socket";
const Home = ({games,brands,callPopuplarGamesApi}) => {
  useEffect(() => {
    socket.emit("join", {
      msg: `this is test msg`,
      fromAdmin: false,
      userId: localStorage.getItem("userId"),
    });
  }, []);

  return (
    <Layout>
      <div className='home'>
        <HeroBanner />
        <HeroSlider games={games}/>
        {brands.length>0 &&   <WhyUs brands={brands}/>}
        <OfferSection />
        {callPopuplarGamesApi && <PopularGames />}
        <Testimonial />
      </div>
    </Layout>
  );
};

export default Home;
