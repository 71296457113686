import { useState, useEffect } from "react";
import { Tabs, Tab, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import "./home.css";
import popular1 from "../../assets/images/home/1.png";
import popular5 from "../../assets/images/home/5.png";
import popular10 from "../../assets/images/home/10.png";
import trending from "../../assets/images/home/trending.svg";
import { getGames } from "../../utils/Api";
import { openGameLink } from "../../utils";
import { useCallback } from "react";
import NoData from "../noData/index";

const PopularGames = () => {
  const initialPaginationValue = {
    limit: 14,
    page: 0,
    totalPages: 0,
    totalResults: 0,
    sort: "-playCount,-createdAt",
    gamesCat: "all",
  };
  const [paginationValue, setPaginationValue] = useState(
    initialPaginationValue
  );
  const [gamesArrGrid, setGamesArrGrid] = useState({
    first: [],
    second: [],
    third: [],
    fourth: [],
  });

  const gamesArrChange = useCallback((game) => {
    const gamesArrGridCopy = { ...gamesArrGrid };
    gamesArrGridCopy.first = game.splice(0, 4);
    gamesArrGridCopy.second = game.splice(0, 3);
    gamesArrGridCopy.third = game.splice(0, 3);
    gamesArrGridCopy.fourth = game.splice(0, 4);
    setGamesArrGrid(gamesArrGridCopy);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getGamesByType = async () => {
      const res = await getGames(paginationValue);
      if (res.status === 200) {
        const { results, limit, page, totalPages, totalResults } =
          res?.data?.game;
        gamesArrChange(results || []);
        const pageData = {
          limit,
          page,
          totalPages,
          totalResults,
          sort: paginationValue.sort,
          gamesCat: paginationValue.gamesCat,
        };
        if (JSON.stringify(paginationValue) !== JSON.stringify(pageData)) {
          setPaginationValue(pageData);
        }
      }
    };
    getGamesByType();
  }, [gamesArrChange, paginationValue]);

  const handlegamesView = (tab) => {
    const copyPagination = { ...paginationValue };
    copyPagination.gamesCat = tab;
    setPaginationValue(copyPagination);
  };

  return (
    <div className='popular-games-section'>
      <div className='container'>
        <div className='popular-games-content'>
          <div className='popular-section-title'>
            <h6>Popular games</h6>
          </div>

          <div className='tab-section'>
            <Tabs
              defaultActiveKey='all'
              id='uncontrolled-tab-example'
              onSelect={(tab) => handlegamesView(tab)}>
              <Tab eventKey='all' title='ALL'>
                {gamesArrGrid.first.length > 0 ? (
                  <PopularTabContent gamesArrGrid={gamesArrGrid} />
                ) : (
                  <NoData heading='No Games' />
                )}
              </Tab>
              <Tab eventKey='slots' title='SLOT'>
                {gamesArrGrid.first.length > 0 ? (
                  <PopularTabContent gamesArrGrid={gamesArrGrid} />
                ) : (
                  <NoData heading='No Slots Games' />
                )}
              </Tab>
              <Tab eventKey='keno' title='KENO'>
                {gamesArrGrid.first.length > 0 ? (
                  <PopularTabContent gamesArrGrid={gamesArrGrid} />
                ) : (
                  <NoData heading='No Keno Games' />
                )}
              </Tab>
              <Tab eventKey='fish' title='FISH'>
                {gamesArrGrid.first.length > 0 ? (
                  <PopularTabContent gamesArrGrid={gamesArrGrid} />
                ) : (
                  <NoData heading='No Fish Games' />
                )}
              </Tab>
            </Tabs>
          </div>

          <div className='popular-section-link'>
            <Link to='/games'>Show me more</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopularGames;

const PopularTabContent = ({ gamesArrGrid }) => {
  const navigate = useNavigate();

  return (
    <div className='popular-tab-content'>
      <div className='popular-small-grid'>
        {gamesArrGrid.first.length > 0 &&
          gamesArrGrid.first.map((el) => (
            <div className='popular-small-box' key={el?.id ?? el?._id}>
              <div className='popular-badge'>
                <img src={trending} alt='popular' />
              </div>
              <img src={el?.logo || popular1} alt='popular' />
              <div className='game-info'>
                <h5>{el?.name || ""}</h5>
                <p>By {el?.brandName?.name || ""}</p>
                <div className='games-brand-btn'>
                  <Button
                    onClick={() =>
                      openGameLink(el?.downloadUrl, el?.id, navigate)
                    }>
                    Download
                  </Button>
                  <Link to={`/download?game=${el.id}`}>About</Link>
                </div>
              </div>
            </div>
          ))}
      </div>

      <div className='popular-grid'>
        <div className='popular-grid-left'>
          {gamesArrGrid.second.length > 0 && (
            <>
              <div className='popular-big-box'>
                <img
                  src={gamesArrGrid?.second[0]?.logo || popular5}
                  alt='popular'
                />
                <div className='game-info'>
                  <h5>{gamesArrGrid?.second[0]?.name}</h5>
                  <p>By {gamesArrGrid?.second[0]?.brandName?.name}</p>
                  <div className='games-brand-btn'>
                    <Button
                      onClick={() =>
                        openGameLink(
                          gamesArrGrid?.second[0]?.downloadUrl,
                          gamesArrGrid?.second[0]?.id,
                          navigate
                        )
                      }>
                      Download
                    </Button>
                    <Link to={`/download?game=${gamesArrGrid?.second[0].id}`}>
                      About
                    </Link>
                  </div>
                </div>
              </div>
            </>
          )}
          <div className='popular-inner-grid'>
            {gamesArrGrid.second.length > 1 &&
              gamesArrGrid.second.map((el, i) => (
                <>
                  {i > 0 && (
                    <>
                      <div className='popular-grid-box' key={el?.id ?? el?._id}>
                        <img src={el?.logo || popular1} alt='popular' />
                        <div className='game-info'>
                          <h5>{el?.name || ""}</h5>
                          <p>By {el?.brandName?.name || ""}</p>
                          <div className='games-brand-btn'>
                            <Button
                              onClick={() =>
                                openGameLink(el?.downloadUrl, el?.id, navigate)
                              }>
                              Download
                            </Button>
                            <Link to={`/download?game=${el.id}`}>About</Link>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </>
              ))}
          </div>
        </div>
        <div className='popular-grid-right'>
          <div className='popular-inner-grid'>
            {gamesArrGrid.third.length > 0 &&
              gamesArrGrid.third.map((el, i) => (
                <>
                  {i < 2 && (
                    <>
                      <div className='popular-grid-box' key={el?.id ?? el?._id}>
                        <img src={el?.logo || popular1} alt='popular' />
                        <div className='game-info'>
                          <h5>{el?.name || ""}</h5>
                          <p>By {el?.brandName?.name || ""}</p>
                          <div className='games-brand-btn'>
                            <Button
                              onClick={() =>
                                openGameLink(el?.downloadUrl, el?.id, navigate)
                              }>
                              Download
                            </Button>
                            <Link to={`/download?game=${el.id}`}>About</Link>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </>
              ))}
          </div>
          {gamesArrGrid.third.length > 1 && (
            <>
              <div className='popular-big-box'>
                <div className='popular-badge'>
                  <img src={trending} alt='popular' />
                </div>
                <img
                  src={gamesArrGrid.third[2]?.logo || popular10}
                  alt='popular'
                />
                <div className='game-info'>
                  <h5>{gamesArrGrid.third[2]?.name || ""}</h5>
                  <p>By {gamesArrGrid?.third[2]?.brandName?.name}</p>
                  <div className='games-brand-btn'>
                    <Button
                      onClick={() =>
                        openGameLink(
                          gamesArrGrid?.third[2]?.downloadUrl,
                          navigate
                        )
                      }>
                      Download
                    </Button>
                    <Link to={`/download?game=${gamesArrGrid?.third[2].id}`}>
                      About
                    </Link>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <div className='popular-small-grid'>
        {!!gamesArrGrid.fourth.length &&
          gamesArrGrid.fourth.map((el) => (
            <div className='popular-small-box' key={el?.id ?? el?._id}>
              <div className='popular-badge'>
                <img src={trending} alt='popular' />
              </div>
              <img src={el?.logo || popular1} alt='popular' />
              <div className='game-info'>
                <h5>{el?.name || ""}</h5>
                <p>By {el?.brandName?.name || ""}</p>
                <div className='games-brand-btn'>
                  <Button
                    onClick={() =>
                      openGameLink(el?.downloadUrl, el?.id, navigate)
                    }>
                    Download
                  </Button>
                  <Link to={`/download?game=${el.id}`}>About</Link>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};
