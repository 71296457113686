import { useRef, useState } from "react";
import { Tooltip, Overlay } from "react-bootstrap";
import facebook from "../../../assets/images/clientlayout/socialcard/facebook.svg";
import twitter from "../../../assets/images/clientlayout/socialcard/twitter.svg";
// import insta from "../../../assets/images/clientlayout/socialcard/instagram.svg";
import telegram from "../../../assets/images/clientlayout/socialcard/telegram.svg";
import link from "../../../assets/images/clientlayout/socialcard/link.svg";
import copylink from "../../../assets/images/clientlayout/socialcard/copylink.svg";
import "./menu.css";
import { useContext } from "react";
import UserContext from "../../../context/UserContext";
import { copyText } from "../../../utils";
import {
  FacebookShareButton,
  // InstapaperShareButton,
  TelegramShareButton,
  TwitterShareButton,
} from "react-share";
import { client } from "../../../config/keys";

const SocialCard = () => {
  const { user } = useContext(UserContext);
  const [showTooltipCode, setShowTooltip] = useState(false);
  const [showTooltipLink, setShowTooltipLink] = useState(false);
  const target = useRef(null);
  const linkTarget = useRef(null);
  const [shareData] = useState({
    shareUrl: `${client}register?referral=${user.referralCode}`,
    quote: "A place to have a fun.",
    description: "We provide a simple and easy to play games.",
    hastags: "#game#play#casino",
  });

  const copyTextValue = (string, copyType) => {
    copyText(string);
    if (copyType === "code") {
      setShowTooltip(true);
      setTimeout(() => {
        setShowTooltip(false);
      }, 1500);
    } else {
      setShowTooltipLink(true);
      setTimeout(() => {
        setShowTooltipLink(false);
      }, 1500);
    }
  };
  return (
    <div className='social-card-content'>
      <div className='social-card-heading'>
        <h1>Get $5 bonus for each invited friendreferal</h1>
      </div>
      <div className='social-card-share'>
        <div className='social-card-copy'>
          <p>Copy referal code</p>
          <div className='social-card-input'>
            <input
              type='text'
              placeholder='777mike’sfriend'
              class='form-control'
              value={user.referralCode}
            />
            <img
              style={{ cursor: "pointer" }}
              src={copylink}
              alt=''
              onClick={() => copyTextValue(user.referralCode, "code")}
              ref={target}
            />
            <Overlay
              target={target.current}
              show={showTooltipCode}
              placement='top'>
              {(props) => (
                <Tooltip id='overlay-example' {...props}>
                  Code Copied
                </Tooltip>
              )}
            </Overlay>
          </div>
        </div>
        <div className='social-cards-share'>
          <p>or share referal link</p>
          <div className='social-share'>
            <FacebookShareButton
              url={shareData.shareUrl}
              quote={shareData.quote}
              hashtag={shareData.hastags}
              description={shareData.description}
              className='Demo__some-network__share-button'>
              <img src={facebook} alt='twitter-icon' />
            </FacebookShareButton>
            <TwitterShareButton
              url={shareData.shareUrl}
              quote={shareData.quote}
              hashtag={shareData.hastags}
              description={shareData.description}
              className='Demo__some-network__share-button'>
              <img src={twitter} alt='twitter-icon' />
            </TwitterShareButton>

            {/* <InstapaperShareButton
              url={shareData.shareUrl}
              quote={shareData.quote}
              hashtag={shareData.hastags}
              description={shareData.description}
              className='Demo__some-network__share-button'>
              <img src={insta} alt='telegram-icon' />
            </InstapaperShareButton> */}
            <TelegramShareButton
              url={shareData.shareUrl}
              quote={shareData.quote}
              hashtag={shareData.hastags}
              description={shareData.description}
              className='Demo__some-network__share-button'>
              <img src={telegram} alt='telegram-iFon' />
            </TelegramShareButton>
            <div
              onClick={() => copyTextValue(shareData.shareUrl)}
              style={{ cursor: "pointer" }}
              ref={linkTarget}>
              <img src={link} alt='link-icon' />
              <Overlay
                target={linkTarget.current}
                show={showTooltipLink}
                placement='top'>
                {(props) => (
                  <Tooltip id='overlay-example' {...props}>
                    Link Copied
                  </Tooltip>
                )}
              </Overlay>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocialCard;
