import { Link,useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import popular7 from "../../assets/images/home/7.png";
import PagePagination from "../pagination/Pagination";
import { openGameLink } from "../../utils";

const AllGamesContent = ({handlePagination,games,paginationValue}) => {
  const navigate = useNavigate()
  return (
    <div className="all-games-content">
      <div className="popular-tab-content">
        <div className="popular-small-grid">
          {games.length>0 && games.map((el)=>(
            <> <div className="popular-small-box" key={el?.id ?? el?._id}>
            <img src={el.logo||popular7} alt="popular" />
            <div className="game-info">
              <h5>{el.name||''}</h5>
              <p>By {el?.brandName?.name || ''}</p>
              <div className="games-brand-btn">
                <Button onClick={()=>openGameLink(el?.downloadUrl,el?.id,navigate)}>Download</Button>
                <Link to={`/download?game=${el.id}`}>About</Link>
              </div>
            </div>
          </div></>
          ))}          
        </div>
      </div>
      {paginationValue?.totalPages > 1 && 
       <PagePagination handlePagination={handlePagination}  page={paginationValue?.page} totalpages={paginationValue?.totalPages}/>
      }
    </div>
  );
};

export default AllGamesContent;
