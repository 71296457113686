import Layout from "../layout/layout";
import OfferBanner from "./offer-banner";
import OfferContent from "./offer-content";

import "./offer.css";

const Offer = () => {
  return (
    <Layout>
      <div className="offer-page">
        <OfferBanner />
        <OfferContent />
      </div>
    </Layout>
  );
};

export default Offer;
