import PageTitle from "../home/pageTitle";
import gameIcon from "../../assets/images/game/game-icon.svg";
import { useContext } from "react";
import UserContext from "../../context/UserContext";

const GamesBanner = () => {
  const {landingPagesImg} = useContext(UserContext);
  return (
    <div className="game-banner" style={{background: `${landingPagesImg?.gamePageImg ? `url(${landingPagesImg?.gamePageImg})` : ''}`}}>
      <div className="container">
        <PageTitle pageTitle="Games" titleIcon={gameIcon} />

        <div className="game-grid">
          <div className="game-left">
            <h1>Play the most exciting games</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GamesBanner;
