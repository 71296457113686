import React, { useContext, useEffect } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import UserContext from "../../context/UserContext";
// import toast from "react-hot-toast";

const UserRoute = ({ setOpenKYCModal, openKYCModal }) => {
  const navigate = useNavigate();
  const { setUser, setTokens, user, kycStatus } = useContext(UserContext);
  const tokenData = localStorage.getItem("token");
  useEffect(() => {
    const tokenParsed = JSON.parse(tokenData) || {};
    const { token, expires } = tokenParsed?.access || {};
    if (!token || (token && new Date() > new Date(expires))) {
      setUser(null);
      setTokens({});
      localStorage.removeItem("token");
      localStorage.removeItem("userId");
      navigate("/login");
    }
    if (user) {
      if (!kycStatus) {
        setOpenKYCModal(true);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.nickName]);

  if (user) {
    if (user?.kycCompleted || window.location.pathname === "/client-profile") {
      return <Outlet />;
    } 
    else {
      return <Navigate to='/' />;
    }
  }
};
export default UserRoute;
